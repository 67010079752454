import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import {
  closeOutline,
  createOutline,
  eyeOutline,
  searchOutline,
  trashOutline,
} from "ionicons/icons";

import api_client from "../../api/client";

import { tRootState } from "../../store";
import { tVendors } from "../../store/types/app.types";
import { updateVendors } from "../../store/cacheReducer";

import withAuth from "../../hoc/withAuth/withAuth";

import DashboardLayout from "../../layouts/Dashboard/Dashboard";

import Icon from "../../icons/Icon";

import VerticalBarLoader from "../../components/Loaders/VerticalBarLoader";

import { assertNotNull, getSkip } from "../../utils/func";

const Vendors = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { accessToken, adminDetails } = useSelector(
    (state: tRootState) => state.auth
  );
  const { vendors: chdVendors, numVendors } = useSelector(
    (state: tRootState) => state.cache
  );

  assertNotNull(adminDetails);

  const actions = adminDetails.CurrentRole;

  const [pageLoading, setPageLoading] = useState<
    "" | "prev-page" | "next-page"
  >("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const fetchInterval = useRef<number | null>(null);

  const [reload, setReload] = useState(false);

  const [search, setSearch] = useState("");

  const filterSortExists = !!search;

  const [page, setPage] = useState<number>(1);
  const [loadPage, setLoadPage] = useState<number>(1);
  const [division] = useState<number>(15);

  const [numRecords, setNumRecords] = useState(numVendors);
  const [pagination, setPagination] = useState(0);
  const [vendors, setVendors] = useState<tVendors | null>(chdVendors);

  useEffect(() => {
    if (loadPage && !pageLoading && !error) setPage(loadPage);
  }, [loadPage, pageLoading, error]);

  useEffect(() => {
    if (fetchInterval.current) window.clearInterval(fetchInterval.current);

    setLoading(true);
    setError(false);

    let url = `/vendors?page=${loadPage}&division=${division}${
      search ? `&search=${search}` : ""
    }`;

    fetchInterval.current = window.setInterval(() => {
      api_client({
        url,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => {
          const vnds: tVendors = res.data.data.vendors;
          const numVendors: number = res.data.data.meta_data.numRecords;

          setNumRecords(numVendors);
          setPagination(res.data.data.meta_data.pagination);

          if (loadPage > 1 && !vnds.length) {
            setLoadPage(loadPage - 1);
          } else {
            setVendors(vnds);

            if (loadPage === 1 && !filterSortExists) {
              dispatch(
                updateVendors({
                  vendors: vnds,
                  numVendors: numVendors,
                })
              );
            }
          }
        })
        .catch(() => {
          setError(true);
        })
        .finally(() => {
          setLoading(false);
          setPageLoading("");
          if (fetchInterval.current)
            window.clearInterval(fetchInterval.current);
        });
    }, 3000);
  }, [
    search,
    filterSortExists,
    loadPage,
    division,
    accessToken,
    dispatch,
    reload,
  ]);

  const skip = getSkip(page, division);
  const start = skip + 1;
  const end = skip + division;

  return (
    <DashboardLayout>
      <div className="page-header">
        <div className="page-header__left">
          <h3 className="page-header__heading">Vendors</h3>
          <ul className="page-header__breadcrumb">
            <li className="page-header__breadcrumb-item">
              <Link to="#">Home</Link>
            </li>
            <li className="page-header__breadcrumb-item">
              <Link to="#">Vendors</Link>
            </li>
            <li className="page-header__breadcrumb-item">
              <span>List</span>
            </li>
          </ul>
        </div>
        <div className="page-header__right">
          {actions.CR_V ? (
            <Link to="/add-vendor" className="btn">
              <Icon name="add" />
              Add Vendor
            </Link>
          ) : null}
        </div>
      </div>
      <div className="table-block-1">
        <div className="table-controls">
          <div className="table-controls__left">
            <div className="search-box search-box--filled">
              <IonIcon icon={searchOutline} className="search-box__icon" />
              <input
                className="input search-box__input"
                type="text"
                placeholder="Search by name..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              {search ? (
                <span
                  className="search-box__clear"
                  onClick={() => setSearch("")}
                >
                  <IonIcon icon={closeOutline} />
                </span>
              ) : null}
            </div>
            <div className="table-controls__filters"></div>
          </div>
          <div className="table-controls__right"></div>
        </div>
        <div>
          <div className="table-block">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>
                      <div className="th">ID</div>
                    </th>
                    <th>
                      <div className="th">Name</div>
                    </th>
                    <th>
                      <div className="th">Contact Person</div>
                    </th>
                    <th>
                      <div className="th">Address</div>
                    </th>
                    <th>
                      <div className="th">Products</div>
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {!vendors && loading ? (
                    <tr className="no-hover">
                      <td colSpan={6}>
                        <VerticalBarLoader sm />
                      </td>
                    </tr>
                  ) : null}
                  {vendors && !vendors.length ? (
                    <tr className="no-hover">
                      <td colSpan={6}>
                        <div className="tempty">
                          <Icon name="no-data" className="tempty__img" />
                          {numVendors ? (
                            <p>
                              There are no vendors in current filter.{" "}
                              <span
                                className="text-link"
                                onClick={() => setReload((rl) => !rl)}
                              >
                                Reload
                              </span>
                            </p>
                          ) : (
                            <p>
                              You are yet to add any vendor.{" "}
                              {actions.CR_V ? (
                                <Link to="/add-vendor" className="text-link">
                                  Add Vendor
                                </Link>
                              ) : null}
                            </p>
                          )}
                        </div>
                      </td>
                    </tr>
                  ) : null}
                  {vendors?.map((vendor) => (
                    <tr key={vendor._id}>
                      <td>#{vendor._id.slice(0, 6)}</td>
                      <td>{vendor.Name}</td>
                      <td>
                        <div className="tuser tuser--sm">
                          <img
                            src={vendor.ContactPerson.PicturePath}
                            alt=""
                            className="tuser__img"
                          />
                          <div className="tuser__main">
                            <p className="tuser__name">
                              {vendor.ContactPerson.Name}
                            </p>
                            <p className="tuser__email">
                              {vendor.ContactPerson.EmailAddress ||
                                vendor.ContactPerson.TelephoneNumber}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td>
                        {vendor.Address.State}, {vendor.Address.Country.Name}
                      </td>
                      <td>{vendor.NumProducts} products</td>
                      <td>
                        <div className="tactions">
                          {actions.UP_V ? (
                            <button
                              className="taction"
                              onClick={() =>
                                navigate(`/edit-vendor/${vendor._id}`)
                              }
                            >
                              <IonIcon icon={createOutline} />
                            </button>
                          ) : null}
                          <button className="taction">
                            <IonIcon icon={eyeOutline} />
                          </button>
                          {vendor.Type !== "Internal" && actions.DL_V ? (
                            <button className="taction">
                              <IonIcon icon={trashOutline} />
                            </button>
                          ) : null}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {vendors && loading ? <div className="line-loader"></div> : null}
          </div>
        </div>
        <div className="table-footer">
          <div className="division">
            {numRecords ? (
              <>
                Showing {start} - {end} of {numRecords}
              </>
            ) : null}
          </div>
          <div className="pagination-2">
            {numRecords ? (
              <>
                <button
                  className={cls(pageLoading === "prev-page" && "active")}
                  disabled={page === 1}
                  onClick={() => {
                    if (page === 1) return;

                    setPageLoading("prev-page");
                    setLoadPage(page - 1);
                  }}
                >
                  Previous
                </button>
                <button
                  className={cls(pageLoading === "next-page" && "active")}
                  disabled={!pagination || page === pagination}
                  onClick={() => {
                    if (page === pagination) return;

                    setPageLoading("next-page");
                    setLoadPage(page + 1);
                  }}
                >
                  Next
                </button>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default withAuth(Vendors);
