import { useState } from "react";
import { Link } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import {
  cloudUploadOutline,
  eyeOutline,
  trashBinOutline,
} from "ionicons/icons";

import DashboardLayout from "../../layouts/Dashboard/Dashboard";

import { TextInput } from "../../components/Form/Input/Input";

import ScrollLink from "../../components/Scrollable/ScrollLink";
import ScrollContent from "../../components/Scrollable/ScrollContent";
import { SimpleSelect } from "../../components/Form/Select/Select";
import Icon from "../../icons/Icon";
import TelephoneInput from "../../components/Form/TelephoneInput/TelephoneInput";

const AddRider = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [email, setEmail] = useState("");

  const [countryOptions] = useState(["Nigeria", "United States of America"]);
  const [country, setCountry] = useState("");

  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [suburb, setSuburb] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [addressLine3, setAddressLine3] = useState("");

  return (
    <DashboardLayout>
      <div className="page-header">
        <div className="page-header__left">
          <h3 className="page-header__heading">Add Rider</h3>
          <ul className="page-header__breadcrumb">
            <li className="page-header__breadcrumb-item">
              <Link to="">Home</Link>
            </li>
            <li className="page-header__breadcrumb-item">
              <Link to="/riders">Riders</Link>
            </li>
            <li className="page-header__breadcrumb-item">
              <span>Add</span>
            </li>
          </ul>
        </div>
        <div className="page-header__right">
          <Link to="/riders" className="btn">
            <IonIcon icon={eyeOutline} />
            View Riders
          </Link>
        </div>
      </div>
      <div className="form-tab">
        <ul className="form-tab__main">
          <li className="form-tab__item">
            <ScrollLink
              className={({ active }) =>
                cls("form-tab__link", active && "active")
              }
              targetId="details"
            >
              <span> Details </span>
            </ScrollLink>
          </li>
          <li className="form-tab__item">
            <ScrollLink
              className={({ active }) =>
                cls("form-tab__link", active && "active")
              }
              targetId="address"
            >
              <span> Address </span>
            </ScrollLink>
          </li>
          <li className="form-tab__item">
            <ScrollLink
              className={({ active }) =>
                cls("form-tab__link", active && "active")
              }
              targetId="picture"
            >
              <span> Picture (optional) </span>
            </ScrollLink>
          </li>
        </ul>
      </div>
      <div className="stepped-form-container">
        <ScrollContent className="stepped-form" id="details">
          <div className="stepped-form__header">
            <h3 className="stepped-form__heading">Details</h3>
            <p className="stepped-form__subheading">
              Edit details about the transaction
            </p>
          </div>
          <div className="stepped-form__main">
            <div className="stepped-form__grid ">
              <div className="fgroup">
                <label htmlFor="name-field" className="label">
                  First Name
                </label>
                <TextInput
                  type="text"
                  placeholder="First name"
                  bind={[firstName, setFirstName]}
                />
              </div>
              <div className="fgroup">
                <label htmlFor="price-field" className="label">
                  Last Name
                </label>
                <TextInput
                  type="text"
                  placeholder="Last name"
                  bind={[lastName, setLastName]}
                />
              </div>
              <div className="fgroup">
                <label htmlFor="price-field" className="label">
                  Email Address
                </label>
                <TextInput
                  type="email"
                  placeholder="e.g janedoe@gmail.com"
                  bind={[email, setEmail]}
                />
              </div>
              <div className="fgroup">
                <label htmlFor="price-field" className="label">
                  Phone Number
                </label>
                {/* <TelephoneInput /> */}
              </div>
            </div>
          </div>
        </ScrollContent>
        <ScrollContent className="stepped-form" id="address">
          <div className="stepped-form__header">
            <h3 className="stepped-form__heading">Address</h3>
            <p className="stepped-form__subheading">
              Enter details about user address
            </p>
          </div>
          <div className="stepped-form__main">
            <div className="stepped-form__grid">
              <div className="fgroup">
                <label className="label">Country</label>
                <SimpleSelect
                  type="single"
                  placeholder="Select country..."
                  useSearch={false}
                  bind={[country, setCountry]}
                  data={countryOptions}
                />
              </div>
              <div className="fgroup">
                <label className="label">State</label>
                <TextInput
                  type="text"
                  placeholder="State"
                  bind={[state, setState]}
                />
              </div>
              <div className="fgroup">
                <label className="label">City</label>
                <TextInput
                  type="text"
                  placeholder="City"
                  bind={[city, setCity]}
                />
              </div>
              <div className="fgroup">
                <label className="label">ZIP / Postcode</label>
                <TextInput
                  type="text"
                  placeholder="ZIP / Postal code"
                  bind={[postalCode, setPostalCode]}
                />
              </div>
              <div className="fgroup">
                <label className="label">Suburb</label>
                <TextInput
                  type="text"
                  placeholder="Suburb"
                  bind={[suburb, setSuburb]}
                />
              </div>
              <div className="fgroup">
                <label className="label">Address Line 1</label>
                <TextInput
                  type="text"
                  placeholder="Address Line 1"
                  bind={[addressLine1, setAddressLine1]}
                />
              </div>
              <div className="fgroup">
                <label className="label">Address Line 2</label>
                <TextInput
                  type="text"
                  placeholder="Address Line 2"
                  bind={[addressLine2, setAddressLine2]}
                />
              </div>
              <div className="fgroup">
                <label className="label">Address Line 3</label>
                <TextInput
                  type="text"
                  placeholder="Address Line 3"
                  bind={[addressLine3, setAddressLine3]}
                />
              </div>
            </div>
          </div>
        </ScrollContent>
        <ScrollContent className="stepped-form" id="picture">
          <div className="stepped-form__header">
            <h3 className="stepped-form__heading">Rider photo</h3>
            <p className="stepped-form__subheading">
              Upload rider picture here
            </p>
          </div>
          <div className="stepped-form__main">
            <div className="file">
              <div className="file-input active">
                <input type="file" />
                <div className="file-input__left">
                  <Icon name="upload" />
                  Drop or select file
                </div>
                <div className="file-input__right">
                  <button className="button">
                    <IonIcon icon={trashBinOutline} />
                    Clear 1 files
                  </button>
                  <button className="btn">
                    <IonIcon icon={cloudUploadOutline} /> Add more files
                  </button>
                </div>
              </div>
              <div className="file-previews">
                <div className="file-preview file-preview--media">
                  <div className="file-preview__header">
                    <button className="file-preview__remove">
                      <IonIcon icon={trashBinOutline} />
                    </button>
                    <img
                      src="https://cdn.britannica.com/35/238335-050-2CB2EB8A/Lionel-Messi-Argentina-Netherlands-World-Cup-Qatar-2022.jpg"
                      alt=""
                    />
                  </div>
                  <div className="file-preview__body">
                    <p className="file-preview__name">
                      <strong>IMAGE:</strong> messi.png
                    </p>
                    <span className="file-preview__size"> 314 kB </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ScrollContent>
      </div>
      <footer className="btn-footer">
        <button className="btn">Add Customer</button>
      </footer>
    </DashboardLayout>
  );
};

export default AddRider;
