import AddIcon from "./icons/AddIcon";
import CartIcon from "./icons/CartIcon";
import CheckCircleIcon from "./icons/CheckCircleIcon";
import CheckIcon from "./icons/CheckIcon";
import DollarCircleIcon from "./icons/DollarCircleIcon";
import DollarIcon from "./icons/DollarIcon";
import FilterIcon from "./icons/FilterIcon";
import FolderIcon from "./icons/FolderIcon";
import HammerIcon from "./icons/HammerIcon";
import HomeIcon from "./icons/HomeIcon";
import HourGlassIcon from "./icons/HourGlassIcon";
import NoDataIcon from "./icons/NoDataIcon";
import PackageIcon from "./icons/PackageIcon";
import RolesIcon from "./icons/RolesIcon";
import TickIcon from "./icons/TickIcon";
import UploadIcon from "./icons/UploadIcon";
import UserAccessIcon from "./icons/UserAccessIcon";
import UserCircleIcon from "./icons/UserCircleIcon";
import UserFolderIcon from "./icons/UserFolderIcon";
import UserIcon from "./icons/UserIcon";
import UserSettingsIcon from "./icons/UserSettingsIcon";

const Icon = ({
  name,
  className = "",
}: {
  name: string;
  className?: string;
}) => {
  if (name === "cart") return <CartIcon className={className} />;
  if (name === "folder") return <FolderIcon className={className} />;
  if (name === "dollar") return <DollarIcon className={className} />;
  if (name === "roles") return <RolesIcon className={className} />;
  if (name === "dollar-circle")
    return <DollarCircleIcon className={className} />;
  if (name === "package") return <PackageIcon className={className} />;
  if (name === "user-settings")
    return <UserSettingsIcon className={className} />;
  if (name === "user-circle") return <UserCircleIcon className={className} />;
  if (name === "user") return <UserIcon className={className} />;
  if (name === "hammer") return <HammerIcon className={className} />;
  if (name === "home") return <HomeIcon className={className} />;
  if (name === "hour-glass") return <HourGlassIcon className={className} />;
  if (name === "upload") return <UploadIcon className={className} />;
  if (name === "add") return <AddIcon className={className} />;
  if (name === "filter") return <FilterIcon className={className} />;
  if (name === "check") return <CheckIcon className={className} />;
  if (name === "user-folder") return <UserFolderIcon className={className} />;
  if (name === "user-access") return <UserAccessIcon className={className} />;
  if (name === "tick") return <TickIcon className={className} />;
  if (name === "check-circle") return <CheckCircleIcon className={className} />;
  if (name === "no-data") return <NoDataIcon className={className} />;

  return null;
};

export default Icon;
