import { BrowserRouter, Routes, Route } from "react-router-dom";

import NavigateCleanup from "./hoc/NavigateCleanup/NavigateCleanup";

import Dashboard from "./pages/Dashboard/Dashboard";

import Products from "./pages/Products/Products";
import AddProduct from "./pages/AddProduct/AddProduct";

import Transactions from "./pages/Transactions/Transactions";
import AddTransaction from "./pages/AddTransaction/AddTransaction";

import InvoiceDetails from "./pages/InvoiceDetails/InvoiceDetails";

import Customers from "./pages/Customers/Customers";
import AddCustomer from "./pages/AddCustomer/AddCustomer";

import Expenses from "./pages/Expenses/Expenses";
import AddExpense from "./pages/AddExpense/AddExpense";

import Riders from "./pages/Riders/Riders";
import AddRider from "./pages/AddRider/AddRider";

import Carriers from "./pages/Carriers/Carriers";

import Vendors from "./pages/Vendors/Vendors";
import AddVendor from "./pages/AddEditVendor/AddVendor";
import EditVendor from "./pages/AddEditVendor/AddVendor";

import RolesPermission from "./pages/RolesPermission/RolesPermission";

import Login from "./pages/Login/Login";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import ResetPassword from "./pages/ResetPassword/ResetPassword";

function App() {
  return (
    <BrowserRouter>
      <NavigateCleanup />
      <Routes>
        <Route path="/" element={<Dashboard />} />

        <Route path="/products" element={<Products />} />
        <Route path="/add-product" element={<AddProduct />} />

        <Route path="/transactions" element={<Transactions />} />
        <Route path="/add-transaction" element={<AddTransaction />} />
        <Route path="/transactions/:id/invoice" element={<InvoiceDetails />} />

        <Route path="/expenses" element={<Expenses />} />
        <Route path="/add-expense" element={<AddExpense />} />

        <Route path="/customers" element={<Customers />} />
        <Route path="/add-customer" element={<AddCustomer />} />

        <Route path="/riders" element={<Riders />} />
        <Route path="/add-rider" element={<AddRider />} />

        <Route path="/carriers" element={<Carriers />} />

        <Route path="/vendors" element={<Vendors />} />
        <Route path="/add-vendor" element={<AddVendor />} />
        <Route path="/edit-vendor/:id" element={<EditVendor />} />

        <Route path="/roles-permission" element={<RolesPermission />} />

        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
