import { FormEvent, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import {
  cloudUploadOutline,
  eyeOutline,
  trashBinOutline,
} from "ionicons/icons";

import { tRootState } from "../../store";
import { tCountry, tVendorDetails } from "../../store/types/app.types";

import api_client from "../../api/client";

import DashboardLayout from "../../layouts/Dashboard/Dashboard";

import useData from "../../hooks/useData/useData";

import Icon from "../../icons/Icon";

import ScrollLink from "../../components/Scrollable/ScrollLink";
import ScrollContent from "../../components/Scrollable/ScrollContent";

import { TextInput } from "../../components/Form/Input/Input";
import Select from "../../components/Form/Select/Select";
import TelephoneInput, {
  tSingleTelephoneInputCountry,
} from "../../components/Form/TelephoneInput/TelephoneInput";
import SuccessModal, {
  tSuccess,
} from "../../components/UtilModals/SuccessModal";
import ErrorModal from "../../components/UtilModals/ErrorModal";
import Preloader from "../../components/Preloader/Preloader";
import { assertNotNull, formatBytes } from "../../utils/func";

const AddEditVendor = () => {
  const { id: vendorId } = useParams();

  const navigate = useNavigate();

  const accessToken = useSelector(
    (state: tRootState) => state.auth.accessToken
  );
  const { countries, telephoneCountries } = useSelector(
    (state: tRootState) => state.cache
  );

  assertNotNull(accessToken);

  const { fetchCountries } = useData();

  const [vendor, setVendor] = useState<tVendorDetails | null>(null);
  const [updates, setUpdates] = useState(true);

  // Vendor Info
  const [vendorName, setVendorName] = useState("");

  // Contact Person Info
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const [telephoneCountry, setTelephoneCountry] =
    useState<tSingleTelephoneInputCountry>(null);
  const [telephone, setTelephone] = useState("");

  const [picture, setPicture] = useState<File | null>(null);
  const pictureInputRef = useRef<HTMLInputElement>(null);

  // Address Info
  const [country, setCountry] = useState<tCountry | null>(null);
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [suburb, setSuburb] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [addressLine3, setAddressLine3] = useState("");

  const submitBtnRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);

  const [error, setError] = useState("");
  const [success, setSuccess] = useState<tSuccess | null>(null);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {};

  const fetchVendor = (vendorId: string, accessToken: string) =>
    new Promise<tVendorDetails>((resolve, reject) => {
      api_client({
        url: `/vendors/${vendorId}`,
        headers: { Authorization: `Bearer ${accessToken}` },
      })
        .then((res) => {
          const vnd: tVendorDetails = res.data.data;

          setVendorName(vnd.Name);

          setName(vnd.ContactPerson.Name);
          setEmail(vnd.ContactPerson.EmailAddress || "");
          setTelephone(vnd.ContactPerson.TelephoneNumber || "");

          setCountry(vnd.Address.Country);
          setState(vnd.Address.State);
          setCity(vnd.Address.City);
          setPostalCode(vnd.Address.PostalCode || "");
          setSuburb(vnd.Address.Suburb || "");
          setAddressLine1(vnd.Address.AddressLine1);
          setAddressLine2(vnd.Address.AddressLine2 || "");
          setAddressLine3(vnd.Address.AddressLine3 || "");

          setVendor(vnd);

          resolve(vnd);
        })
        .catch((err) => {
          reject(err);
        });
    });

  useEffect(() => {
    if (
      !vendor ||
      !vendorName ||
      !name ||
      (!email && !telephoneCountry && !telephone) ||
      !country ||
      !state ||
      !city ||
      !addressLine1
    )
      return;

    // TODO: A more efficient approach to test changes
    // console.log("Vendor", vendor.Name, vendorName, vendor.Name !== vendorName);
    // console.log(
    //   "Name",
    //   vendor.ContactPerson.Name,
    //   name,
    //   vendor.ContactPerson.Name !== name
    // );
    // console.log(
    //   "Email",
    //   vendor.ContactPerson.EmailAddress,
    //   email,
    //   (vendor.ContactPerson.EmailAddress || "") !== email
    // );
    // console.log(
    //   "Telephone Country",
    //   vendor.ContactPerson.TelephoneCountry?.Code,
    //   telephoneCountry?.isoCode,
    //   vendor.ContactPerson.TelephoneCountry?.Code !== telephoneCountry?.isoCode
    // );
    // console.log(
    //   "Telephone Number",
    //   vendor.ContactPerson.TelephoneNumber,
    //   telephone,
    //   (vendor.ContactPerson.TelephoneNumber || "") !== telephone
    // );
    // console.log(
    //   "Country",
    //   vendor.Address.Country._id,
    //   country._id,
    //   vendor.Address.Country._id !== country._id
    // );
    // console.log(
    //   "State",
    //   vendor.Address.State,
    //   state,
    //   vendor.Address.State !== state
    // );
    // console.log(
    //   "City",
    //   vendor.Address.City,
    //   city,
    //   vendor.Address.City !== city
    // );
    // console.log(
    //   "Postal Code",
    //   vendor.Address.PostalCode || "",
    //   postalCode,
    //   (vendor.Address.PostalCode || "") !== postalCode
    // );
    // console.log(
    //   "Suburb",
    //   vendor.Address.Suburb || "",
    //   suburb,
    //   (vendor.Address.Suburb || "") !== suburb
    // );
    // console.log(
    //   "Address Line 1",
    //   vendor.Address.AddressLine1,
    //   addressLine1,
    //   vendor.Address.AddressLine1 !== addressLine1
    // );
    // console.log(
    //   "Address Line 2",
    //   vendor.Address.AddressLine2 || "",
    //   addressLine2,
    //   (vendor.Address.AddressLine2 || "") !== addressLine2
    // );
    // console.log(
    //   "Address Line 3",
    //   vendor.Address.AddressLine3 || "",
    //   addressLine3,
    //   (vendor.Address.AddressLine3 || "") !== addressLine3
    // );

    if (vendor.Name !== vendorName) return setUpdates(true);

    if (vendor.ContactPerson.Name !== name) return setUpdates(true);
    if ((vendor.ContactPerson.EmailAddress || "") !== email)
      return setUpdates(true);
    if (
      vendor.ContactPerson.TelephoneCountry?.Code !== telephoneCountry?.isoCode
    )
      return setUpdates(true);
    if ((vendor.ContactPerson.TelephoneNumber || "") !== telephone)
      return setUpdates(true);

    if (vendor.Address.Country._id !== country._id) return setUpdates(true);
    if (vendor.Address.State !== state) return setUpdates(true);
    if (vendor.Address.City !== city) return setUpdates(true);
    if ((vendor.Address.PostalCode || "") !== postalCode)
      return setUpdates(true);
    if ((vendor.Address.Suburb || "") !== suburb) return setUpdates(true);
    if (vendor.Address.AddressLine1 !== addressLine1) return setUpdates(true);
    if ((vendor.Address.AddressLine2 || "") !== addressLine2)
      return setUpdates(true);
    if ((vendor.Address.AddressLine3 || "") !== addressLine3)
      return setUpdates(true);

    if (picture) return setUpdates(true);

    setUpdates(false);
  }, [
    vendor,

    vendorName,

    name,
    email,
    telephoneCountry,
    telephone,
    picture,

    country,
    state,
    city,
    postalCode,
    suburb,
    addressLine1,
    addressLine2,
    addressLine3,
  ]);

  useEffect(() => {
    fetchCountries();
  }, [fetchCountries]);

  useEffect(() => {
    if (!vendor?.ContactPerson.TelephoneCountry?.Code) return;

    setTelephoneCountry(
      telephoneCountries.find(
        (cnt) => cnt.isoCode === vendor.ContactPerson.TelephoneCountry?.Code
      ) || null
    );
  }, [telephoneCountries, vendor?.ContactPerson.TelephoneCountry?.Code]);

  useEffect(() => {
    if (!vendorId) return;

    fetchVendor(vendorId, accessToken)
      .then(() => {
        // All good
      })
      .catch(() => {
        navigate("/not-found");
      });
  }, [vendorId, accessToken, navigate]);

  if (vendorId && !vendor) return <Preloader />;

  return (
    <DashboardLayout>
      <SuccessModal success={success} />
      <ErrorModal errorMsg={error} closeHandler={() => setError("")} />

      <input
        type="file"
        style={{ display: "none" }}
        ref={pictureInputRef}
        onChange={(e) => {
          const files = e.target.files;
          if (files && files.length) {
            setPicture(files[0]);

            e.target.value = "";
          }
        }}
        accept=".jpg,.png,.jpeg"
      />

      <div className="page-header">
        <div className="page-header__left">
          <h3 className="page-header__heading">Add Vendor</h3>
          <ul className="page-header__breadcrumb">
            <li className="page-header__breadcrumb-item">
              <Link to="">Home</Link>
            </li>
            <li className="page-header__breadcrumb-item">
              <Link to="/vendors">Vendors</Link>
            </li>
            <li className="page-header__breadcrumb-item">
              <span>Add</span>
            </li>
          </ul>
        </div>
        <div className="page-header__right">
          <Link to="/vendors" className="btn">
            <IonIcon icon={eyeOutline} />
            View Vendors
          </Link>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="form-tab">
          <ul className="form-tab__main">
            <li className="form-tab__item">
              <ScrollLink
                className={({ active }) =>
                  cls("form-tab__link", active && "active")
                }
                targetId="details"
              >
                <span> Details </span>
              </ScrollLink>
            </li>
            <li className="form-tab__item">
              <ScrollLink
                className={({ active }) =>
                  cls("form-tab__link", active && "active")
                }
                targetId="contact-person"
              >
                <span> Contact Person </span>
              </ScrollLink>
            </li>
            <li className="form-tab__item">
              <ScrollLink
                className={({ active }) =>
                  cls("form-tab__link", active && "active")
                }
                targetId="address"
              >
                <span> Address </span>
              </ScrollLink>
            </li>
          </ul>
        </div>
        <div className="stepped-form-container">
          <ScrollContent className="stepped-form" id="details">
            <div className="stepped-form__header">
              <h3 className="stepped-form__heading">Details</h3>
              <p className="stepped-form__subheading">
                Edit details about the vendor
              </p>
            </div>
            <div className="stepped-form__main">
              <div className="stepped-form__grid">
                <div className="grid-full">
                  <div className="fgroup">
                    <label htmlFor="name-field" className="label">
                      Vendor Name
                    </label>
                    <TextInput
                      type="text"
                      placeholder="Vendor name"
                      bind={[vendorName, setVendorName]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </ScrollContent>
          <ScrollContent className="stepped-form" id="contact-person">
            <div className="stepped-form__header">
              <h3 className="stepped-form__heading">Contact Person</h3>
              <p className="stepped-form__subheading">
                Edit details about the contact person
              </p>
            </div>
            <div className="stepped-form__main">
              <div className="stepped-form__grid ">
                <div className="grid-full">
                  <div className="fgroup">
                    <label htmlFor="name-field" className="label">
                      Name
                    </label>
                    <TextInput
                      type="text"
                      placeholder="First name"
                      bind={[name, setName]}
                    />
                  </div>
                </div>
                <div className="fgroup">
                  <label htmlFor="price-field" className="label">
                    Email Address
                  </label>
                  <TextInput
                    type="email"
                    placeholder="e.g janedoe@gmail.com"
                    bind={[email, setEmail]}
                  />
                </div>
                <div className="fgroup">
                  <label htmlFor="price-field" className="label">
                    Phone Number
                  </label>
                  <TelephoneInput
                    placeholder="Enter phone number"
                    countries={telephoneCountries}
                    countryBind={[telephoneCountry, setTelephoneCountry]}
                    telBind={[telephone, setTelephone]}
                  />
                </div>
                <div className="grid-full">
                  <div className="fgroup">
                    <label className="label">Picture</label>
                    <div className="file">
                      <div className={cls("file-input", picture && "active")}>
                        <input type="file" />
                        <div
                          className="file-input__left"
                          onClick={() => pictureInputRef.current?.click()}
                        >
                          <Icon name="upload" />
                          Drop or select file
                        </div>
                        {picture ? (
                          <div className="file-input__right">
                            <button
                              type="button"
                              className="button"
                              onClick={() => setPicture(null)}
                            >
                              <IonIcon icon={trashBinOutline} />
                              Clear 1 file
                            </button>
                            <button
                              type="button"
                              className="btn"
                              onClick={() => pictureInputRef.current?.click()}
                            >
                              <IonIcon icon={cloudUploadOutline} /> Change file
                            </button>
                          </div>
                        ) : null}
                      </div>
                      {picture ? (
                        <div className="file-previews">
                          <div className="file-preview file-preview--media">
                            <div
                              className="file-preview__header"
                              onClick={() =>
                                window.open(
                                  URL.createObjectURL(picture),
                                  "_blank"
                                )
                              }
                            >
                              <button
                                type="button"
                                className="file-preview__remove"
                                onClick={(e) => {
                                  setPicture(null);
                                  e.stopPropagation();
                                }}
                              >
                                <IonIcon icon={trashBinOutline} />
                              </button>
                              <img src={URL.createObjectURL(picture)} alt="" />
                            </div>
                            <div className="file-preview__body">
                              <p className="file-preview__name">
                                <strong>IMAGE:</strong> {picture.name}
                              </p>
                              <span className="file-preview__size">
                                {formatBytes(picture.size)}
                              </span>
                            </div>
                          </div>
                        </div>
                      ) : vendor ? (
                        <div className="file-previews">
                          <div className="file-preview file-preview--media">
                            <div
                              className="file-preview__header"
                              onClick={() =>
                                window.open(
                                  vendor.ContactPerson.PicturePath,
                                  "_blank"
                                )
                              }
                            >
                              <img
                                src={vendor.ContactPerson.PicturePath}
                                alt=""
                              />
                            </div>
                            {/* <div className="file-preview__body">
                            <p className="file-preview__name">
                              <strong>IMAGE:</strong> {vendor.Slug}
                            </p>
                          </div> */}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ScrollContent>
          <ScrollContent className="stepped-form" id="address">
            <div className="stepped-form__header">
              <h3 className="stepped-form__heading">Address</h3>
              <p className="stepped-form__subheading">
                Enter address of the vendor
              </p>
            </div>
            <div className="stepped-form__main">
              <div className="stepped-form__grid">
                <div className="fgroup">
                  <label className="label">Country</label>
                  <Select<tCountry>
                    type="single"
                    placeholder="Select country"
                    useSearch={true}
                    data={countries}
                    bind={[country, setCountry]}
                    transform={{ uniqueKey: "_id", displayKey: "Name" }}
                  />
                </div>
                <div className="fgroup">
                  <label className="label">State</label>
                  <TextInput
                    type="text"
                    placeholder="State"
                    bind={[state, setState]}
                  />
                </div>
                <div className="fgroup">
                  <label className="label">City</label>
                  <TextInput
                    type="text"
                    placeholder="City"
                    bind={[city, setCity]}
                  />
                </div>
                <div className="fgroup">
                  <label className="label">ZIP / Postcode</label>
                  <TextInput
                    type="text"
                    placeholder="ZIP / Postal code"
                    bind={[postalCode, setPostalCode]}
                  />
                </div>
                <div className="fgroup">
                  <label className="label">Suburb</label>
                  <TextInput
                    type="text"
                    placeholder="Suburb"
                    bind={[suburb, setSuburb]}
                  />
                </div>
                <div className="fgroup">
                  <label className="label">Address Line 1</label>
                  <TextInput
                    type="text"
                    placeholder="Address Line 1"
                    bind={[addressLine1, setAddressLine1]}
                  />
                </div>
                <div className="fgroup">
                  <label className="label">Address Line 2</label>
                  <TextInput
                    type="text"
                    placeholder="Address Line 2"
                    bind={[addressLine2, setAddressLine2]}
                  />
                </div>
                <div className="fgroup">
                  <label className="label">Address Line 3</label>
                  <TextInput
                    type="text"
                    placeholder="Address Line 3"
                    bind={[addressLine3, setAddressLine3]}
                  />
                </div>
              </div>
            </div>
          </ScrollContent>
        </div>
        <footer className="btn-footer">
          <button
            className="btn"
            ref={submitBtnRef}
            disabled={
              !vendorName ||
              !name ||
              (!email && !(telephoneCountry && telephone)) ||
              !country ||
              !state ||
              !city ||
              !addressLine1 ||
              !updates
            }
          >
            {vendor ? "Edit" : "Add"} Vendor
          </button>
        </footer>
      </form>
    </DashboardLayout>
  );
};

export default AddEditVendor;
