import { Link } from "react-router-dom";

import { IonIcon } from "@ionic/react";
import { arrowForwardOutline, eyeOffOutline } from "ionicons/icons";

import Auth from "../../layouts/Auth/Auth";

const ResetPassword = () => {
  return (
    <Auth>
      <div className="auth__header">
        <h1 className="auth__heading">Reset Password</h1>
        <p className="auth__sub-heading">
          Kindly fill in the details below to reset your account password
        </p>
      </div>
      <form className="auth__form">
        <div className="form-group">
          <label htmlFor="new-password-field" className="form-label">
            New password
          </label>
          <div className="form-input-group">
            <input
              type="text"
              className="form-input"
              placeholder="Enter new password"
              id="new-password-field"
            />
            <button type="button">
              <IonIcon icon={eyeOffOutline} />
            </button>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="confirm-password-field" className="form-label">
            Confirm password
          </label>
          <div className="form-input-group">
            <input
              type="text"
              className="form-input"
              placeholder="Confirm password"
              id="confirm-password-field"
            />
            <button type="button">
              <IonIcon icon={eyeOffOutline} />
            </button>
          </div>
        </div>
        <div className="form-group form-group--centered">
          <label htmlFor="otp-field" className="form-label">
            OTP
          </label>
          <div className="pin-input-group">
            <input type="password" className="pin-input" id="otp-field" />
            <input type="password" className="pin-input" />
            <input type="password" className="pin-input" />
            <input type="password" className="pin-input" />
          </div>
        </div>
        <div className="auth__form-footer">
          <button className="btn btn--md btn--dark">
            Reset Password <IonIcon icon={arrowForwardOutline} />
          </button>
        </div>
      </form>
      <div className="auth__footer">
        Remember your password? <Link to="/login">Back to Login</Link>
      </div>
    </Auth>
  );
};

export default ResetPassword;
