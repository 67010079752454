import { useState } from "react";

import { tRole } from "../../store/types/app.types";

import Icon from "../../icons/Icon";
import RoleDetails from "../RoleDetails/RoleDetails";

const RoleCard = ({ role }: { role: tRole }) => {
  const [showDetails, setShowDetails] = useState(false);

  return (
    <>
      {showDetails ? (
        <RoleDetails
          roleId={role._id}
          closeHandler={() => setShowDetails(false)}
        />
      ) : null}
      <div className="role">
        <div className="role__header">
          <div
            className="role__icon-block"
            style={{ backgroundColor: role.Color }}
          >
            <Icon name="user-folder" />
          </div>
          <h3 className="role__heading">{role.Name}</h3>
        </div>
        <div className="role__body">
          {role.Admins.length ? (
            <div className="role__users">
              {role.Admins.map((roleAdmin) => (
                <div className="role__user" key={roleAdmin._id}>
                  <img
                    src={roleAdmin.PicturePath}
                    alt=""
                    className="role__user-img"
                  />
                </div>
              ))}
            </div>
          ) : null}
          <p className="role__users-count">Total {role.AdminsCount} admins</p>
        </div>
        <button className="button" onClick={() => setShowDetails(true)}>
          <Icon name="user-access" />
          View Role
        </button>
      </div>
    </>
  );
};

export default RoleCard;
