import { PayloadAction, createSlice, current } from "@reduxjs/toolkit";

import { getState, saveState } from "./global";

import { tAdminLogged } from "./types/app.types";

type AuthType = {
  accessToken: string | null;
  adminDetails: tAdminLogged | null;
};

const AuthInitialState: AuthType = {
  accessToken: null,
  adminDetails: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState: getState<AuthType>("auth", AuthInitialState),
  reducers: {
    login(state, { payload }: PayloadAction<AuthType>) {
      state.accessToken = payload.accessToken;
      state.adminDetails = payload.adminDetails;

      saveState("auth", current(state));
    },
    updateAdminDetails(state, { payload }: PayloadAction<tAdminLogged>) {
      state.adminDetails = payload;

      saveState("auth", current(state));
    },
    updateAdminDetailsKey(
      state,
      {
        payload: { key, value },
      }: PayloadAction<{ key: keyof tAdminLogged; value: any }>
    ) {
      if (!state.adminDetails) return;

      state.adminDetails = { ...state.adminDetails, [key]: value };

      saveState("auth", current(state));
    },
    logout(state) {
      state.accessToken = null;
      state.adminDetails = null;
    },
  },
});

export const { login, updateAdminDetails, updateAdminDetailsKey, logout } =
  authSlice.actions;

export default authSlice.reducer;
