import { ChangeEvent } from "react";

import Icon from "../../../icons/Icon";

type tBoxRadio = {
  label: string;
  name: string;
  checked: boolean;
  changeHandler: (e: ChangeEvent<HTMLInputElement>) => void;
};

const BoxRadio = ({ label, name, checked, changeHandler }: tBoxRadio) => {
  return (
    <label className="box-radio">
      <p>{label}</p>
      <input
        type="radio"
        name={name}
        className="box-radio__input"
        checked={checked}
        onChange={changeHandler}
      />
      <Icon name="check-circle" className="box-radio__icon" />
    </label>
  );
};

export default BoxRadio;
