import { IonIcon } from "@ionic/react";
import {
  arrowDownOutline,
  arrowUpOutline,
  caretDownOutline,
  createOutline,
  cubeOutline,
  eyeOutline,
  folderOutline,
  logoUsd,
  trashOutline,
  walletOutline,
  chevronUpOutline,
  arrowUndoOutline,
} from "ionicons/icons";

import withAuth from "../../hoc/withAuth/withAuth";

import DashboardLayout from "../../layouts/Dashboard/Dashboard";

const Dashboard = () => {
  return (
    <DashboardLayout>
      <div className="p-small">
        <div className="info-grids mb-small">
          <div className="info-grid">
            <div className="info-grid__main">
              <div className="info-grid__icon-block info-grid__icon-block--2">
                <IonIcon icon={cubeOutline} />
              </div>
              <div className="info-grid__info">
                <p className="info-grid__info-key">Total Orders</p>
                <p className="info-grid__info-value">&#8358;25,786k</p>
              </div>
            </div>
            <div className="info-grid__footer">
              <span className="info-grid__diff info-grid__diff--down">
                <span>
                  <IonIcon icon={arrowDownOutline} />
                </span>
                -32.45%
              </span>
              Decreased last month
            </div>
          </div>
          <div className="info-grid">
            <div className="info-grid__main">
              <div className="info-grid__icon-block info-grid__icon-block--1">
                <IonIcon icon={walletOutline} />
              </div>
              <div className="info-grid__info">
                <p className="info-grid__info-key">Total Income</p>
                <p className="info-grid__info-value">&#8358;16,085k</p>
              </div>
            </div>
            <div className="info-grid__footer">
              <span className="info-grid__diff info-grid__diff--up">
                <span>
                  <IonIcon icon={arrowUpOutline} />
                </span>
                +32.45%
              </span>
              Increased last month
            </div>
          </div>
          <div className="info-grid">
            <div className="info-grid__main">
              <div className="info-grid__icon-block info-grid__icon-block--4">
                <IonIcon icon={folderOutline} />
              </div>
              <div className="info-grid__info">
                <p className="info-grid__info-key">Total Expense</p>
                <p className="info-grid__info-value">&#8358;27,432k</p>
              </div>
            </div>
            <div className="info-grid__footer">
              <span className="info-grid__diff info-grid__diff--down">
                <span>
                  <IonIcon icon={arrowDownOutline} />
                </span>
                -32.45%
              </span>
              Decreased last month
            </div>
          </div>
          <div className="info-grid">
            <div className="info-grid__main">
              <div className="info-grid__icon-block info-grid__icon-block--3">
                <IonIcon icon={logoUsd} />
              </div>
              <div className="info-grid__info">
                <p className="info-grid__info-key">Net Profit</p>
                <p className="info-grid__info-value">&#8358;38,503k</p>
              </div>
            </div>
            <div className="info-grid__footer">
              <span className="info-grid__diff info-grid__diff--up">
                <span>
                  <IonIcon icon={arrowUpOutline} />
                </span>
                +32.45%
              </span>
              Increased last month
            </div>
          </div>
          <div className="info-grid">
            <div className="info-grid__main">
              <div className="info-grid__icon-block info-grid__icon-block--4">
                <IonIcon icon={folderOutline} />
              </div>
              <div className="info-grid__info">
                <p className="info-grid__info-key">Total Assets</p>
                <p className="info-grid__info-value">&#8358;27,432k</p>
              </div>
            </div>
            <div className="info-grid__footer">
              <span className="info-grid__diff info-grid__diff--down">
                <span>
                  <IonIcon icon={arrowDownOutline} />
                </span>
                -32.45%
              </span>
              Decreased last month
            </div>
          </div>
        </div>
        <div className="table-block">
          <div className="table-block__header">
            <div className="table-header">
              <h3 className="table-header__heading">Recent Transactions</h3>
              <p className="table-header__sub-heading">
                Summary of last few transactions performed
              </p>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th></th>
                  <th>
                    <div className="th">ID</div>
                  </th>
                  <th>
                    <div className="th">Type</div>
                  </th>
                  <th>
                    <div className="th">Origin</div>
                  </th>
                  <th>
                    <div className="th">Destination</div>
                  </th>
                  <th>
                    <div className="th">
                      Date
                      <span>
                        <IonIcon icon={caretDownOutline} />
                      </span>
                    </div>
                  </th>
                  <th>
                    <div className="th th--right">
                      Income
                      <span>
                        <IonIcon icon={caretDownOutline} />
                      </span>
                    </div>
                  </th>
                  <th>
                    <div className="th th--right">
                      Expense
                      <span>
                        <IonIcon icon={caretDownOutline} />
                      </span>
                    </div>
                  </th>
                  <th>
                    <div className="th">Status</div>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <button className="texpand">
                      <IonIcon icon={chevronUpOutline} />
                    </button>
                  </td>
                  <td>#28361</td>
                  <td>Sale</td>
                  <td>
                    <div className="tuser tuser--sm">
                      <div className="tuser__main">
                        <p className="tuser__name">Smabirm Lagos</p>
                        <p className="tuser__email">Branch</p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="tuser tuser--sm">
                      <img
                        src="https://isomorphic-furyroad.s3.amazonaws.com/public/avatars/avatar-04.webp"
                        alt=""
                        className="tuser__img"
                      />
                      <div className="tuser__main">
                        <p className="tuser__name">Guillermo McLaughlin</p>
                        <p className="tuser__email">Customer</p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="tdate">
                      <p className="tdate__date">November 19, 2018</p>
                      <p className="tdate__time">9:26 AM</p>
                    </div>
                  </td>
                  <td>
                    <p className="align-right titem">&#8358;740.00</p>
                  </td>
                  <td>
                    <p className="align-right titem">&#8358;100.00</p>
                  </td>
                  <td>
                    <span className="tbadge tbadge--success">Confirmed</span>
                  </td>
                  <td>
                    <div className="tactions">
                      <button className="taction">
                        <IonIcon icon={eyeOutline} />
                      </button>
                      <button className="taction">
                        <IonIcon icon={createOutline} />
                      </button>
                      <button className="taction">
                        <IonIcon icon={trashOutline} />
                      </button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <button className="texpand">
                      <IonIcon icon={chevronUpOutline} />
                    </button>
                  </td>
                  <td>#28361</td>
                  <td>Purchase</td>
                  <td>
                    <div className="tuser tuser--sm">
                      <div className="tuser__main">
                        <p className="tuser__name">Dangote</p>
                        <p className="tuser__email">Manufacturer</p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="tuser tuser--sm">
                      <div className="tuser__main">
                        <p className="tuser__name">Smabirm Lagos</p>
                        <p className="tuser__email">Branch</p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="tdate">
                      <p className="tdate__date">November 1, 2018</p>
                      <p className="tdate__time">2:26 AM</p>
                    </div>
                  </td>
                  <td>
                    <p className="align-right titem">ASSET</p>
                  </td>
                  <td>
                    <p className="align-right titem">&#8358;50.00</p>
                  </td>
                  <td>
                    <span className="tbadge tbadge--success">Confirmed</span>
                  </td>
                  <td>
                    <div className="tactions">
                      <button className="taction">
                        <IonIcon icon={eyeOutline} />
                      </button>
                      <button className="taction">
                        <IonIcon icon={createOutline} />
                      </button>
                      <button className="taction">
                        <IonIcon icon={trashOutline} />
                      </button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <button className="texpand">
                      <IonIcon icon={chevronUpOutline} />
                    </button>
                  </td>
                  <td>#28361</td>
                  <td>Transport</td>
                  <td>
                    <div className="tuser tuser--sm">
                      <div className="tuser__main">
                        <p className="tuser__name">Smabirm Lagos</p>
                        <p className="tuser__email">Branch</p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="tuser tuser--sm">
                      <div className="tuser__main">
                        <p className="tuser__name">Smabirm Abuja</p>
                        <p className="tuser__email">Branch</p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="tdate">
                      <p className="tdate__date">April 3, 2019</p>
                      <p className="tdate__time">2:54 AM</p>
                    </div>
                  </td>
                  <td>
                    <p className="align-right titem">N/A</p>
                  </td>
                  <td>
                    <p className="align-right titem">&#8358;260.00</p>
                  </td>
                  <td>
                    <span className="tbadge tbadge--info">
                      Out For Delivery
                    </span>
                  </td>
                  <td>
                    <div className="tactions">
                      <button className="taction">
                        <IonIcon icon={eyeOutline} />
                      </button>
                      <button className="taction">
                        <IonIcon icon={createOutline} />
                      </button>
                      <button className="taction">
                        <IonIcon icon={trashOutline} />
                      </button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <button className="texpand">
                      <IonIcon icon={chevronUpOutline} />
                    </button>
                  </td>
                  <td>#28361</td>
                  <td>Sale</td>
                  <td>
                    <div className="tuser tuser--sm">
                      <div className="tuser__main">
                        <p className="tuser__name">Smabirm Abuja</p>
                        <p className="tuser__email">Branch</p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="tuser tuser--sm">
                      <img
                        src="https://isomorphic-furyroad.s3.amazonaws.com/public/avatars/avatar-04.webp"
                        alt=""
                        className="tuser__img"
                      />
                      <div className="tuser__main">
                        <p className="tuser__name">Guillermo McLaughlin</p>
                        <p className="tuser__email">Customer</p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="tdate">
                      <p className="tdate__date">November 19, 2018</p>
                      <p className="tdate__time">9:26 AM</p>
                    </div>
                  </td>
                  <td>
                    <p className="align-right titem">&#8358;740.00</p>
                  </td>
                  <td>
                    <p className="align-right titem">&#8358;65.50</p>
                  </td>
                  <td>
                    <span className="tbadge tbadge--success">Confirmed</span>
                  </td>
                  <td>
                    <div className="tactions">
                      <button className="taction">
                        <IonIcon icon={eyeOutline} />
                      </button>
                      <button className="taction">
                        <IonIcon icon={createOutline} />
                      </button>
                      <button className="taction">
                        <IonIcon icon={trashOutline} />
                      </button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <button className="texpand">
                      <IonIcon icon={chevronUpOutline} />
                    </button>
                  </td>
                  <td>#28361</td>
                  <td>
                    <div className="tflex">
                      <IonIcon icon={arrowUndoOutline} /> Sale
                    </div>
                  </td>
                  <td>
                    <div className="tuser tuser--sm">
                      <img
                        src="https://isomorphic-furyroad.s3.amazonaws.com/public/avatars/avatar-04.webp"
                        alt=""
                        className="tuser__img"
                      />
                      <div className="tuser__main">
                        <p className="tuser__name">Guillermo McLaughlin</p>
                        <p className="tuser__email">Customer</p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="tuser tuser--sm">
                      <div className="tuser__main">
                        <p className="tuser__name">Smabirm Abuja</p>
                        <p className="tuser__email">Branch</p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="tdate">
                      <p className="tdate__date">November 19, 2018</p>
                      <p className="tdate__time">9:26 AM</p>
                    </div>
                  </td>
                  <td>
                    <p className="align-right titem">ASSET</p>
                  </td>
                  <td>
                    <p className="align-right titem">&#8358;350.00</p>
                  </td>
                  <td>
                    <span className="tbadge tbadge--success">Confirmed</span>
                  </td>
                  <td>
                    <div className="tactions">
                      <button className="taction">
                        <IonIcon icon={eyeOutline} />
                      </button>
                      <button className="taction">
                        <IonIcon icon={createOutline} />
                      </button>
                      <button className="taction">
                        <IonIcon icon={trashOutline} />
                      </button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <button className="texpand">
                      <IonIcon icon={chevronUpOutline} />
                    </button>
                  </td>
                  <td>#28361</td>
                  <td>Sale</td>
                  <td>
                    <div className="tuser tuser--sm">
                      <div className="tuser__main">
                        <p className="tuser__name">Smabirm Lagos</p>
                        <p className="tuser__email">Branch</p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="tuser tuser--sm">
                      <img
                        src="https://isomorphic-furyroad.s3.amazonaws.com/public/avatars/avatar-01.webp"
                        alt=""
                        className="tuser__img"
                      />
                      <div className="tuser__main">
                        <p className="tuser__name">Orville Kub</p>
                        <p className="tuser__email">Customer</p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="tdate">
                      <p className="tdate__date">November 1, 2018</p>
                      <p className="tdate__time">2:26 AM</p>
                    </div>
                  </td>
                  <td>
                    <p className="align-right titem">&#8358;567.00</p>
                  </td>
                  <td>
                    <p className="align-right titem">&#8358;8.50</p>
                  </td>
                  <td>
                    <span className="tbadge tbadge--info">
                      Out For Delivery
                    </span>
                  </td>
                  <td>
                    <div className="tactions">
                      <button className="taction">
                        <IonIcon icon={eyeOutline} />
                      </button>
                      <button className="taction">
                        <IonIcon icon={createOutline} />
                      </button>
                      <button className="taction">
                        <IonIcon icon={trashOutline} />
                      </button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <button className="texpand">
                      <IonIcon icon={chevronUpOutline} />
                    </button>
                  </td>
                  <td>#28361</td>
                  <td>Sale</td>
                  <td>
                    <div className="tuser tuser--sm">
                      <div className="tuser__main">
                        <p className="tuser__name">Smabirm Ibadan</p>
                        <p className="tuser__email">Branch</p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="tuser tuser--sm">
                      <img
                        src="https://isomorphic-furyroad.s3.amazonaws.com/public/avatars/avatar-13.webp"
                        alt=""
                        className="tuser__img"
                      />
                      <div className="tuser__main">
                        <p className="tuser__name">Melanie Lehner</p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="tdate">
                      <p className="tdate__date">April 3, 2019</p>
                      <p className="tdate__time">2:54 AM</p>
                    </div>
                  </td>
                  <td>
                    <p className="align-right titem">&#8358;260.00</p>
                  </td>
                  <td>
                    <p className="align-right titem">&#8358;15.65</p>
                  </td>
                  <td>
                    <span className="tbadge tbadge--success">Confirmed</span>
                  </td>
                  <td>
                    <div className="tactions">
                      <button className="taction">
                        <IonIcon icon={eyeOutline} />
                      </button>
                      <button className="taction">
                        <IonIcon icon={createOutline} />
                      </button>
                      <button className="taction">
                        <IonIcon icon={trashOutline} />
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default withAuth(Dashboard);
