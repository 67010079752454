import { Link } from "react-router-dom";

import { IonIcon } from "@ionic/react";
import {
  chevronBackOutline,
  chevronForwardOutline,
  closeOutline,
  createOutline,
  eyeOutline,
  searchOutline,
  trashOutline,
} from "ionicons/icons";

import DashboardLayout from "../../layouts/Dashboard/Dashboard";
import Icon from "../../icons/Icon";

const Carriers = () => {
  return (
    <DashboardLayout>
      <div className="page-header">
        <div className="page-header__left">
          <h3 className="page-header__heading">Carriers</h3>
          <ul className="page-header__breadcrumb">
            <li className="page-header__breadcrumb-item">
              <Link to="#">Home</Link>
            </li>
            <li className="page-header__breadcrumb-item">
              <Link to="#">Carriers</Link>
            </li>
            <li className="page-header__breadcrumb-item">
              <span>List</span>
            </li>
          </ul>
        </div>
        <div className="page-header__right">
          <button className="btn">
            <Icon name="add" />
            Add Carrier
          </button>
        </div>
      </div>
      <div className="table-block-1">
        <div className="table-controls">
          <div className="table-controls__left">
            <div className="search-box search-box--filled">
              <IonIcon icon={searchOutline} className="search-box__icon" />
              <input
                className="input search-box__input"
                type="text"
                placeholder="Search by name / email...."
              />
              <span className="search-box__clear">
                <IonIcon icon={closeOutline} />
              </span>
            </div>
            <div className="table-controls__filters"></div>
          </div>
          <div className="table-controls__right"></div>
        </div>
        <div>
          <div className="table-block">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>
                      <div className="th">ID</div>
                    </th>
                    <th>
                      <div className="th">Name</div>
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>#28361</td>
                    <td>GIG Logistics</td>
                    <td>
                      <div className="tactions">
                        <button className="taction">
                          <IonIcon icon={createOutline} />
                        </button>
                        <button className="taction">
                          <IonIcon icon={eyeOutline} />
                        </button>
                        <button className="taction">
                          <IonIcon icon={trashOutline} />
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="table-footer">
          <div className="division">Showing 1 to 20 of 50 results</div>
          <div className="pagination">
            <button>
              <IonIcon icon={chevronBackOutline} />
            </button>
            <button className="active">1</button>
            <button>2</button>
            <button>3</button>
            <button>
              <IonIcon icon={chevronForwardOutline} />
            </button>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Carriers;
