import { createSlice, PayloadAction, current } from "@reduxjs/toolkit";

import { getState, saveState } from "./global";

import {
  tCountries,
  tCustomers,
  tRoleDetails,
  tRoles,
  tVendorDetails,
  tVendors,
} from "./types/app.types";
import { tTelephoneInputCountries } from "../components/Form/TelephoneInput/TelephoneInput";

type CacheType = {
  roles: tRoles | null;
  rolesCache: tRoleDetails[];

  countries: tCountries;
  telephoneCountries: tTelephoneInputCountries;

  allVendors: tVendors;

  vendors: tVendors | null;
  numVendors: number;

  vendorsCache: tVendorDetails[]; // unused

  customers: tCustomers | null;
  numCustomers: number;
};

const CacheInitialState: CacheType = {
  roles: null,
  rolesCache: [],

  countries: [],
  telephoneCountries: [],

  allVendors: [],

  vendors: null,
  numVendors: 0,

  vendorsCache: [], // unused

  customers: null,
  numCustomers: 0,
};

const cacheSlice = createSlice({
  name: "cache",
  initialState: getState<CacheType>("cache", CacheInitialState),
  reducers: {
    updateRoles(state, { payload }: PayloadAction<tRoles>) {
      state.roles = payload;

      saveState<CacheType>("cache", current(state));
    },
    updateRolesCache(state, { payload }: PayloadAction<tRoleDetails>) {
      state.rolesCache = [
        payload,
        ...state.rolesCache
          .filter((product) => product._id !== payload._id)
          .slice(0, 9),
      ];

      saveState("cache", current(state));
    },
    updateCountries(state, { payload }: PayloadAction<tCountries>) {
      state.countries = payload;
      state.telephoneCountries = payload.map((country) => ({
        name: country.Name,
        flag: country.FlagImgPath,
        isoCode: country.Code,
        telephoneCode: country.TelephoneCode,
      }));

      saveState<CacheType>("cache", current(state));
    },
    updateAllVendors(state, { payload }: PayloadAction<tVendors>) {
      state.allVendors = payload;

      saveState<CacheType>("cache", current(state));
    },
    updateVendors(
      state,
      { payload }: PayloadAction<{ vendors: tVendors; numVendors: number }>
    ) {
      state.vendors = payload.vendors;
      state.numVendors = payload.numVendors;

      saveState("cache", current(state));
    },
    updateVendorsCache(state, { payload }: PayloadAction<tVendorDetails>) {
      state.vendorsCache = [
        payload,
        ...state.vendorsCache
          .filter((vendor) => vendor._id !== payload._id)
          .slice(0, 9),
      ];

      saveState("cache", current(state));
    },
    updateCustomers(
      state,
      {
        payload,
      }: PayloadAction<{ customers: tCustomers; numCustomers: number }>
    ) {
      state.customers = payload.customers;
      state.numCustomers = payload.numCustomers;

      saveState("cache", current(state));
    },
  },
});

export const {
  updateRoles,
  updateRolesCache,
  updateCountries,
  updateAllVendors,
  updateVendors,
  updateVendorsCache,
  updateCustomers,
} = cacheSlice.actions;

export default cacheSlice.reducer;
