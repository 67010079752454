import { useState } from "react";
import { Link } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import {
  chevronBackOutline,
  chevronForwardOutline,
  closeOutline,
  createOutline,
  eyeOutline,
  searchOutline,
  trashOutline,
} from "ionicons/icons";

import DashboardLayout from "../../layouts/Dashboard/Dashboard";
import Icon from "../../icons/Icon";

const Expenses = () => {
  const [showFilters, setShowFilters] = useState(false);

  const toggleFilters = () => {
    setShowFilters((sf) => {
      if (sf) {
        document.querySelector("body")?.classList.remove("modal-open");
      } else {
        document.querySelector("body")?.classList.add("modal-open");
      }

      return !sf;
    });
  };

  return (
    <DashboardLayout>
      {showFilters ? (
        <div className="modal-overlay" onClick={() => toggleFilters()}></div>
      ) : null}
      <div className="page-header">
        <div className="page-header__left">
          <h3 className="page-header__heading">Expenses</h3>
          <ul className="page-header__breadcrumb">
            <li className="page-header__breadcrumb-item">
              <Link to="#">Home</Link>
            </li>
            <li className="page-header__breadcrumb-item">
              <Link to="#">Expenses</Link>
            </li>
            <li className="page-header__breadcrumb-item">
              <span>List</span>
            </li>
          </ul>
        </div>
        <div className="page-header__right">
          <button className="btn">
            <Icon name="add" />
            Add Expense
          </button>
        </div>
      </div>
      <div className={cls("tfilter", showFilters && "open")}>
        <div className="tfilter__header">
          <h3 className="tfilter__heading">Table Filters</h3>
          <span className="tfilter__close" onClick={() => toggleFilters()}>
            <IonIcon icon={closeOutline} />
          </span>
        </div>
        <div className="tfilter__body"></div>
        <div className="tfilter__footer">
          <button className="btn">Show Results</button>
        </div>
      </div>
      <div className="table-block-1">
        <div className="table-controls">
          <div className="table-controls__left">
            <div className="search-box search-box--filled">
              <IonIcon icon={searchOutline} className="search-box__icon" />
              <input
                className="input search-box__input"
                type="text"
                placeholder="Search by name...."
              />
              <span className="search-box__clear">
                <IonIcon icon={closeOutline} />
              </span>
            </div>
            <div className="table-controls__filters"></div>
          </div>
          <div className="table-controls__right">
            <button className="button" onClick={() => toggleFilters()}>
              <Icon name="filter" />
              Filters
            </button>
          </div>
        </div>
        <div>
          <div className="table-block">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>
                      <div className="th">ID</div>
                    </th>
                    <th>
                      <div className="th">Expense</div>
                    </th>
                    <th>
                      <div className="th">Amount</div>
                    </th>
                    <th>
                      <div className="th">Logged By</div>
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>#28361</td>
                    <td>
                      <div className="tinfo">
                        <div className="tinfo__main">
                          <h3 className="tinfo__heading">Electricity</h3>
                          <p className="tinfo__desc">
                            Along With Wordpress Themes & Plugins, We always try
                            to use latest trending techs like React, Next Js,
                            Gatsby Js, GraphQl, Shopify etc to make our products
                            special.
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <p className="titem">&#8358;740.00</p>
                    </td>
                    <td>
                      <div className="tuser tuser--sm">
                        <img
                          src="https://isomorphic-furyroad.s3.amazonaws.com/public/avatars/avatar-04.webp"
                          alt=""
                          className="tuser__img"
                        />
                        <div className="tuser__main">
                          <p className="tuser__name">Guillermo McLaughlin</p>
                          <p className="tuser__email">Admin</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="tactions">
                        <button className="taction">
                          <IonIcon icon={eyeOutline} />
                        </button>
                        <button className="taction">
                          <IonIcon icon={createOutline} />
                        </button>
                        <button className="taction">
                          <IonIcon icon={trashOutline} />
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="table-footer">
          <div className="division">Showing 1 to 20 of 50 results</div>
          <div className="pagination">
            <button>
              <IonIcon icon={chevronBackOutline} />
            </button>
            <button className="active">1</button>
            <button>2</button>
            <button>3</button>
            <button>
              <IonIcon icon={chevronForwardOutline} />
            </button>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Expenses;
