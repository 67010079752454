import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IonIcon } from "@ionic/react";
import { closeOutline } from "ionicons/icons";

import api_client from "../../api/client";

import { tRootState } from "../../store";
import { updateRolesCache } from "../../store/cacheReducer";

import Icon from "../../icons/Icon";

import Spinner from "../Loaders/Spinner";

import { assertNotNull } from "../../utils/func";

const RoleDetails = ({
  roleId,
  closeHandler,
}: {
  roleId: string;
  closeHandler: () => void;
}) => {
  const dispatch = useDispatch();

  const accessToken = useSelector(
    (state: tRootState) => state.auth.accessToken
  );

  assertNotNull(accessToken);

  const rolesCache = useSelector((state: tRootState) => state.cache.rolesCache);

  const role = rolesCache.find((lRole) => lRole._id === roleId);

  useEffect(() => {
    api_client({
      url: `/roles/${roleId}`,
      method: "GET",
      headers: { Authorization: `Bearer ${accessToken}` },
    })
      .then((res) => {
        dispatch(updateRolesCache(res.data.data));
      })
      .catch((err) => {
        if (!role?._id) closeHandler();
      });
  }, [roleId, role?._id, closeHandler, accessToken, dispatch]);

  useEffect(() => {
    document.body.classList.toggle("modal-open");

    return () => {
      document.body.classList.toggle("modal-open");
    };
  }, []);

  if (!role) return <Spinner />;

  return (
    <>
      <div className="modal-overlay"></div>
      <div className="role-group-modal">
        <div className="role-group-modal__main">
          <div className="role-group-modal__header">
            <h3 className="role-group-modal__heading">{role.Name}</h3>
            <button
              className="role-group-modal__close"
              onClick={() => closeHandler()}
            >
              <IonIcon icon={closeOutline} />
            </button>
          </div>
          <div className="role-group-modal__role-access">
            <h3 className="role-group-modal__sub-heading">Role Access</h3>
            {role.ActionGroups.length ? (
              role.ActionGroups.map((actionGroup) => (
                <div
                  className="role-group-modal__action-group"
                  key={actionGroup._id}
                >
                  <h3 className="role-group-modal__action-group-name">
                    {actionGroup.Name}
                  </h3>
                  <div className="role-group-modal__actions">
                    {actionGroup.Actions.map((action) => (
                      <button
                        className="role-group-modal__action"
                        key={action._id}
                      >
                        <Icon name="tick" />
                        {action.Name}{" "}
                        {action.RegionRestricted ? <span>(R)</span> : null}
                      </button>
                    ))}
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center">
                This role can't perform any actions
              </div>
            )}
          </div>
          <div className="role-group-modal__footer">
            <button className="button" onClick={() => closeHandler()}>
              Close
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default RoleDetails;
