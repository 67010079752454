import { useState } from "react";
import { Link } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import {
  addOutline,
  closeOutline,
  eyeOutline,
  removeOutline,
  searchOutline,
  trashOutline,
} from "ionicons/icons";

import DashboardLayout from "../../layouts/Dashboard/Dashboard";

import { NumberInput } from "../../components/Form/Input/Input";

import ScrollLink from "../../components/Scrollable/ScrollLink";
import ScrollContent from "../../components/Scrollable/ScrollContent";
import BoxRadio from "../../components/Form/BoxRadio/BoxRadio";
import { SimpleSelect } from "../../components/Form/Select/Select";

const AddTransaction = () => {
  const [transactionType, setTransactionType] = useState<
    "sale" | "purchase" | "transport"
  >("sale");

  const [amount, setAmount] = useState<string | number>("");

  const [receiveTypeOptions] = useState(["Delivery", "Pickup"]);
  const [receiveType, setReceiveType] = useState("Delivery");

  const [carrierTypeOptions] = useState(["External Carrier", "Internal Rider"]);
  const [carrierType, setCarrierType] = useState("External Carrier");

  return (
    <DashboardLayout>
      <div className="page-header">
        <div className="page-header__left">
          <h3 className="page-header__heading">Add Transaction</h3>
          <ul className="page-header__breadcrumb">
            <li className="page-header__breadcrumb-item">
              <Link to="">Home</Link>
            </li>
            <li className="page-header__breadcrumb-item">
              <Link to="/transactions">Transactions</Link>
            </li>
            <li className="page-header__breadcrumb-item">
              <span>Add</span>
            </li>
          </ul>
        </div>
        <div className="page-header__right">
          <Link to="/transactions" className="btn">
            <IonIcon icon={eyeOutline} />
            View Transactions
          </Link>
        </div>
      </div>
      <div className="form-tab">
        <ul className="form-tab__main">
          <li className="form-tab__item">
            <ScrollLink
              className={({ active }) =>
                cls("form-tab__link", active && "active")
              }
              targetId="details"
            >
              <span> Details </span>
            </ScrollLink>
          </li>
          <li className="form-tab__item">
            <ScrollLink
              className={({ active }) =>
                cls("form-tab__link", active && "active")
              }
              targetId="shipping-info"
            >
              <span> Shipping Information </span>
            </ScrollLink>
          </li>
          <li className="form-tab__item">
            <ScrollLink
              className={({ active }) =>
                cls("form-tab__link", active && "active")
              }
              targetId="item-details"
            >
              <span> Item Details </span>
            </ScrollLink>
          </li>
        </ul>
      </div>
      <div className="stepped-form-container">
        <ScrollContent className="stepped-form" id="details">
          <div className="stepped-form__header">
            <h3 className="stepped-form__heading">Details</h3>
            <p className="stepped-form__subheading">
              Edit details about the transaction
            </p>
          </div>
          <div className="stepped-form__main">
            <div className="stepped-form__grid ">
              <div className="grid-full stepped-form__grid stepped-form__grid--3">
                <BoxRadio
                  label="Sale"
                  name="transactionType"
                  checked={transactionType === "sale"}
                  changeHandler={(e) => {
                    if (e.target.checked) setTransactionType("sale");
                  }}
                />
                <BoxRadio
                  label="Purchase"
                  name="transactionType"
                  checked={transactionType === "purchase"}
                  changeHandler={(e) => {
                    if (e.target.checked) setTransactionType("purchase");
                  }}
                />
                <BoxRadio
                  label="Transport"
                  name="transactionType"
                  checked={transactionType === "transport"}
                  changeHandler={(e) => {
                    if (e.target.checked) setTransactionType("transport");
                  }}
                />
              </div>
              <div className="fgroup">
                <label htmlFor="name-field" className="label">
                  Origin
                </label>
                <div className="search-box search-box--md search-box--filled w-100">
                  <IonIcon icon={searchOutline} className="search-box__icon" />
                  <input
                    className="input search-box__input"
                    type="text"
                    placeholder="Search customer / vendor / branch"
                    value="Branch: "
                  />
                  <span className="search-box__clear">
                    <IonIcon icon={closeOutline} />
                  </span>
                </div>
              </div>
              <div className="fgroup">
                <label htmlFor="price-field" className="label">
                  Destination
                </label>
                <div className="search-box search-box--md search-box--filled w-100">
                  <IonIcon icon={searchOutline} className="search-box__icon" />
                  <input
                    className="input search-box__input"
                    type="text"
                    placeholder="Search customer / vendor / branch"
                    value="Customer: "
                  />
                  <span className="search-box__clear">
                    <IonIcon icon={closeOutline} />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </ScrollContent>
        <ScrollContent className="stepped-form" id="shipping-info">
          <div className="stepped-form__header">
            <h3 className="stepped-form__heading">Shipping Information</h3>
            <p className="stepped-form__subheading">
              Enter details about transaction shipping information
            </p>
          </div>
          <div className="stepped-form__main">
            <div className="stepped-form__grid stepped-form__grid--4">
              <div className="fgroup">
                <label className="label">Receive Type</label>
                <SimpleSelect
                  type="single"
                  placeholder="Select..."
                  useSearch={false}
                  bind={[receiveType, setReceiveType]}
                  data={receiveTypeOptions}
                />
              </div>
              <div className="fgroup">
                <label className="label">Carrier Type</label>
                <SimpleSelect
                  type="single"
                  placeholder="Select..."
                  useSearch={false}
                  bind={[carrierType, setCarrierType]}
                  data={carrierTypeOptions}
                />
              </div>
              <div className="fgroup">
                <label htmlFor="price-field" className="label">
                  Carrier
                </label>
                <div className="search-box search-box--md search-box--filled w-100">
                  <IonIcon icon={searchOutline} className="search-box__icon" />
                  <input
                    className="input search-box__input"
                    type="text"
                    placeholder="Search carrier"
                  />
                  <span className="search-box__clear">
                    <IonIcon icon={closeOutline} />
                  </span>
                </div>
              </div>
              <div className="fgroup">
                <label htmlFor="price-field" className="label">
                  Amount
                </label>
                <NumberInput
                  label="&#8358;"
                  type="amount"
                  placeholder="10"
                  bind={[amount, setAmount]}
                />
              </div>
            </div>
          </div>
        </ScrollContent>
        <ScrollContent className="stepped-form" id="item-details">
          <div className="stepped-form__header">
            <h3 className="stepped-form__heading">Item Details</h3>
            <p className="stepped-form__subheading">
              Enter details about products in this transaction
            </p>
          </div>
          <div className="stepped-form__main">
            <div className="stepped-form__grid">
              <div className="grid-full form-card stepped-form__grid stepped-form__grid--3">
                <div className="fgroup">
                  <label htmlFor="item-field" className="form-label">
                    Item
                  </label>
                  <input
                    type="text"
                    className="input"
                    placeholder="Enter item name"
                    name="item-field"
                  />
                </div>
                <div className="fgroup">
                  <label htmlFor="item-field" className="form-label">
                    Quantity
                  </label>
                  <div className="quantity">
                    <input type="text" className="input" value="0" />
                    <div className="quantity__controls">
                      <button className="quantity__control">
                        <IonIcon icon={removeOutline} />
                      </button>
                      <button className="quantity__control">
                        <IonIcon icon={addOutline} />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="fgroup">
                  <label htmlFor="item-field" className="form-label">
                    Price
                  </label>
                  <div className="flex">
                    <div className="igroup grow">
                      <span> &#8358; </span>
                      <input
                        type="text"
                        className="input"
                        placeholder="100"
                        id="price-field"
                      />
                    </div>
                    <p className="noshrink">
                      Total: <strong>&#8358;0</strong>
                    </p>
                  </div>
                </div>
                <div className="fgroup grid-full">
                  <label htmlFor="description-field" className="label">
                    Description
                  </label>
                  <textarea
                    name=""
                    id="description-field"
                    rows={4}
                    className="input"
                    placeholder="Enter your description"
                  ></textarea>
                </div>
                <div className="grid-full text-right">
                  <button className="text-btn text-btn--1 text-btn--danger">
                    <IonIcon icon={trashOutline} />
                    Remove
                  </button>
                </div>
              </div>
              <div className="grid-full stepped-form__grid stepped-form__grid--4">
                <div>
                  <button className="action-btn">
                    <IonIcon icon={addOutline} />
                    Add Item
                  </button>
                </div>
                <div className="fgroup">
                  <label htmlFor="shipping-field" className="label">
                    Shipping
                  </label>
                  <div className="igroup">
                    <span> &#8358; </span>
                    <input
                      type="text"
                      className="input"
                      placeholder="10"
                      id="shipping-field"
                    />
                  </div>
                </div>
                <div className="fgroup">
                  <label htmlFor="discount-field" className="label">
                    Discount
                  </label>
                  <div className="igroup">
                    <span> &#8358; </span>
                    <input
                      type="text"
                      className="input"
                      placeholder="50"
                      id="discount-field"
                    />
                  </div>
                </div>
                <div className="fgroup">
                  <label htmlFor="discount-field" className="label">
                    Taxes
                  </label>
                  <div className="igroup igroup--right">
                    <span> % </span>
                    <input
                      type="text"
                      className="input"
                      placeholder="15"
                      id="discount-field"
                    />
                  </div>
                </div>
              </div>
              <div className="grid-full">
                <table className="summary-table ml-auto">
                  <tbody>
                    <tr>
                      <td>Subtotal:</td>
                      <td>&#8358;0</td>
                    </tr>
                    <tr>
                      <td>Shipping:</td>
                      <td className="text-danger">&#8358;10</td>
                    </tr>
                    <tr>
                      <td>Discount:</td>
                      <td>&#8358;50</td>
                    </tr>
                    <tr>
                      <td>Taxes:</td>
                      <td className="text-danger">15%</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Total:</strong>
                      </td>
                      <td>&#8358;-47.5</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </ScrollContent>
      </div>
      <footer className="btn-footer">
        <button className="btn">Add Transaction</button>
      </footer>
    </DashboardLayout>
  );
};

export default AddTransaction;
