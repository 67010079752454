import { useState } from "react";
import { Link } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import {
  caretDownOutline,
  chevronBackOutline,
  chevronForwardOutline,
  closeOutline,
  createOutline,
  eyeOutline,
  searchOutline,
  trashOutline,
} from "ionicons/icons";

import DashboardLayout from "../../layouts/Dashboard/Dashboard";
import Icon from "../../icons/Icon";
import { NumberInput } from "../../components/Form/Input/Input";
import { SimpleSelect } from "../../components/Form/Select/Select";

const Products = () => {
  const [showFilters, setShowFilters] = useState(false);

  const [minPrice, setMinPrice] = useState<string | number>("");
  const [maxPrice, setMaxPrice] = useState<string | number>("");

  const [stockOptions] = useState(["All", "In Stock", "Out of Stock"]);
  const [stockFilter, setStockFilter] = useState("All");

  const toggleFilters = () => {
    setShowFilters((sf) => {
      if (sf) {
        document.querySelector("body")?.classList.remove("modal-open");
      } else {
        document.querySelector("body")?.classList.add("modal-open");
      }

      return !sf;
    });
  };

  return (
    <DashboardLayout>
      {showFilters ? (
        <div className="modal-overlay" onClick={() => toggleFilters()}></div>
      ) : null}
      <div className="page-header">
        <div className="page-header__left">
          <h3 className="page-header__heading">Products</h3>
          <ul className="page-header__breadcrumb">
            <li className="page-header__breadcrumb-item">
              <Link to="#">Inventory</Link>
            </li>
            <li className="page-header__breadcrumb-item">
              <Link to="#">Products</Link>
            </li>
            <li className="page-header__breadcrumb-item">
              <span>List</span>
            </li>
          </ul>
        </div>
        <div className="page-header__right">
          <Link to="/add-product" className="btn">
            <Icon name="add" />
            Add Product
          </Link>
        </div>
      </div>
      <div className={cls("tfilter", showFilters && "open")}>
        <div className="tfilter__header">
          <h3 className="tfilter__heading">Table Filters</h3>
          <span className="tfilter__close" onClick={() => toggleFilters()}>
            <IonIcon icon={closeOutline} />
          </span>
        </div>
        <div className="tfilter__body">
          <div className="fgroup">
            <label className="label">Amount</label>
            <div className="range-group">
              <NumberInput
                label="&#8358;"
                type="amount"
                placeholder="e.g 10.0"
                bind={[minPrice, setMinPrice]}
              />
              <div className="range-group__divider"></div>
              <NumberInput
                label="&#8358;"
                type="amount"
                placeholder="e.g 50.0"
                bind={[maxPrice, setMaxPrice]}
              />
            </div>
          </div>
          <div className="fgroup">
            <label className="label">Stock</label>
            <SimpleSelect
              type="single"
              placeholder="Select..."
              useSearch={false}
              bind={[stockFilter, setStockFilter]}
              data={stockOptions}
            />
          </div>
        </div>
        <div className="tfilter__footer">
          <button className="btn">Show Results</button>
        </div>
      </div>
      <div className="table-block-1">
        <div className="table-controls">
          <div className="table-controls__left">
            <div className="search-box search-box--filled">
              <IonIcon icon={searchOutline} className="search-box__icon" />
              <input
                className="input search-box__input"
                type="text"
                placeholder="Search by name / description...."
              />
              <span className="search-box__clear">
                <IonIcon icon={closeOutline} />
              </span>
            </div>
            <div className="table-controls__filters"></div>
          </div>
          <div className="table-controls__right">
            <button className="button" onClick={() => toggleFilters()}>
              <Icon name="filter" />
              Filters
            </button>
          </div>
        </div>
        <div>
          <div className="table-block">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>
                      <div className="th">ID</div>
                    </th>
                    <th>
                      <div className="th">
                        Name
                        <span>
                          <IonIcon icon={caretDownOutline} />
                        </span>
                      </div>
                    </th>
                    <th>
                      <div className="th">
                        Stock
                        <span>
                          <IonIcon icon={caretDownOutline} />
                        </span>
                      </div>
                    </th>
                    <th>
                      <div className="th">
                        Price
                        <span>
                          <IonIcon icon={caretDownOutline} />
                        </span>
                      </div>
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>#912sj182</td>
                    <td>
                      <div className="tinfo">
                        <img
                          src="https://egoleshopping.com/images/detailed/12/sugar.jpg"
                          alt=""
                          className="tinfo__img"
                        />
                        <div className="tinfo__main">
                          <h3 className="tinfo__heading">Dangote Sugar</h3>
                          <p className="tinfo__desc">
                            Our rich tech choice will help you to build high
                            performance applications. We are also known to
                            provide great customer supports to our customers.
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="tstock">
                        <div className="tstock__progress tstock__progress--out">
                          <div></div>
                        </div>
                        <p className="tstock__text">out of stock</p>
                      </div>
                    </td>
                    <td>
                      <div className="titem">&#8358;410.00</div>
                    </td>
                    <td>
                      <div className="tactions">
                        <button className="taction">
                          <IonIcon icon={eyeOutline} />
                        </button>
                        <button className="taction">
                          <IonIcon icon={createOutline} />
                        </button>
                        <button className="taction">
                          <IonIcon icon={trashOutline} />
                        </button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>#912sj182</td>
                    <td>
                      <div className="tinfo">
                        <img
                          src="https://mall.thecbncoop.com/assets/images/products/1633336816Fullied-12g-SKU-500x500.png"
                          alt=""
                          className="tinfo__img"
                        />
                        <div className="tinfo__main">
                          <h3 className="tinfo__heading">
                            Carton of Peak Milk
                          </h3>
                          <p className="tinfo__desc">
                            Our rich tech choice will help you to build high
                            performance applications. We are also known to
                            provide great customer supports to our customers.
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="tstock">
                        <div className="tstock__progress tstock__progress--low">
                          <div style={{ width: "20%" }}></div>
                        </div>
                        <p className="tstock__text">18 low stock</p>
                      </div>
                    </td>
                    <td>
                      <div className="titem">&#8358;881.00</div>
                    </td>
                    <td>
                      <div className="tactions">
                        <button className="taction">
                          <IonIcon icon={eyeOutline} />
                        </button>
                        <button className="taction">
                          <IonIcon icon={createOutline} />
                        </button>
                        <button className="taction">
                          <IonIcon icon={trashOutline} />
                        </button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>#912sj182</td>
                    <td>
                      <div className="tinfo">
                        <img
                          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT-96i3ASiCmbLSIi6vzLlTgzBQSWs96dQcdfFmj85tQCbReLsSpRTt4LFY2Dn_rOojQQs&usqp=CAU"
                          alt=""
                          className="tinfo__img"
                        />
                        <div className="tinfo__main">
                          <h3 className="tinfo__heading">Bad Product</h3>
                          <p className="tinfo__desc">
                            Our rich tech choice will help you to build high
                            performance applications. We are also known to
                            provide great customer supports to our customers.
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="tstock">
                        <div className="tstock__progress tstock__progress--ok">
                          <div style={{ width: "30%" }}></div>
                        </div>
                        <p className="tstock__text">24 in stock</p>
                      </div>
                    </td>
                    <td>
                      <div className="titem">&#8358;587.00</div>
                    </td>
                    <td>
                      <div className="tactions">
                        <button className="taction">
                          <IonIcon icon={eyeOutline} />
                        </button>
                        <button className="taction">
                          <IonIcon icon={createOutline} />
                        </button>
                        <button className="taction">
                          <IonIcon icon={trashOutline} />
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="table-footer">
          <div className="division">Showing 1 to 20 of 50 results</div>
          <div className="pagination">
            <button>
              <IonIcon icon={chevronBackOutline} />
            </button>
            <button className="active">1</button>
            <button>2</button>
            <button>3</button>
            <button>
              <IonIcon icon={chevronForwardOutline} />
            </button>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Products;
