import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import {
  closeOutline,
  createOutline,
  eyeOutline,
  searchOutline,
  trashOutline,
} from "ionicons/icons";

import api_client from "../../api/client";

import { tRootState } from "../../store";
import { tCustomers } from "../../store/types/app.types";
import { updateCustomers } from "../../store/cacheReducer";

import DashboardLayout from "../../layouts/Dashboard/Dashboard";

import CustomersFilters, {
  tCustomersFiltersData,
} from "./CustomersFilters/CustomersFilters";

import VerticalBarLoader from "../../components/Loaders/VerticalBarLoader";

import Icon from "../../icons/Icon";

import { assertNotNull, getSkip, roundDP } from "../../utils/func";

const Customers = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { customers: chdCustomers, numCustomers } = useSelector(
    (state: tRootState) => state.cache
  );
  const { accessToken, adminDetails } = useSelector(
    (state: tRootState) => state.auth
  );

  assertNotNull(adminDetails);

  const actions = adminDetails.CurrentRole;

  const [pageLoading, setPageLoading] = useState<
    "" | "prev-page" | "next-page"
  >("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const fetchInterval = useRef<number | null>(null);

  const [reload, setReload] = useState(false);

  const [search, setSearch] = useState("");

  const [showFilters, setShowFilters] = useState(false);

  const [filters, setFilters] = useState<tCustomersFiltersData>({});

  const filterSortExists = search || Object.keys(filters).length;

  const [page, setPage] = useState<number>(1);
  const [loadPage, setLoadPage] = useState<number>(1);
  const [division] = useState<number>(10);

  const [numRecords, setNumRecords] = useState(numCustomers);
  const [pagination, setPagination] = useState(0);
  const [customers, setCustomers] = useState<tCustomers | null>(chdCustomers);

  useEffect(() => {
    if (loadPage && !pageLoading && !error) setPage(loadPage);
  }, [loadPage, pageLoading, error]);

  useEffect(() => {
    if (fetchInterval.current) window.clearInterval(fetchInterval.current);

    setLoading(true);
    setError(false);

    let url = `/customers?page=${loadPage}&division=${division}${
      search ? `&search=${search}` : ""
    }`;

    if (filters.minWallet) url += `&minWallet=${filters.minWallet}`;
    if (filters.maxWallet) url += `&maxWallet=${filters.maxWallet}`;
    if (filters.overdraft) url += `&overdraft=${filters.overdraft}`;
    if (filters.minOverdraft) url += `&minOverdraft=${filters.minOverdraft}`;
    if (filters.maxOverdraft) url += `&maxOverdraft=${filters.maxOverdraft}`;

    fetchInterval.current = window.setInterval(() => {
      api_client({
        url,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => {
          const cstms: tCustomers = res.data.data.customers;
          const numRecords: number = res.data.data.meta_data.num_records;

          setNumRecords(numRecords);
          setPagination(res.data.data.meta_data.pagination);

          if (loadPage > 1 && !cstms.length) {
            setLoadPage(loadPage - 1);
          } else {
            setCustomers(cstms);

            if (loadPage === 1 && !filterSortExists) {
              dispatch(
                updateCustomers({
                  customers: cstms,
                  numCustomers: numRecords,
                })
              );
            }
          }
        })
        .catch(() => {
          setError(true);
        })
        .finally(() => {
          setLoading(false);
          setPageLoading("");
          if (fetchInterval.current)
            window.clearInterval(fetchInterval.current);
        });
    }, 3000);
  }, [
    search,
    filters.minWallet,
    filters.maxWallet,
    filters.overdraft,
    filters.minOverdraft,
    filters.maxOverdraft,
    filterSortExists,
    loadPage,
    division,
    accessToken,
    dispatch,
    reload,
  ]);

  const skip = getSkip(page, division);
  const start = skip + 1;
  const end = skip + division;

  return (
    <DashboardLayout>
      <CustomersFilters
        open={showFilters}
        handler={(data) => {
          setFilters(data);

          setShowFilters(false);
        }}
      />

      <div className="page-header">
        <div className="page-header__left">
          <h3 className="page-header__heading">Customers</h3>
          <ul className="page-header__breadcrumb">
            <li className="page-header__breadcrumb-item">
              <Link to="#">Home</Link>
            </li>
            <li className="page-header__breadcrumb-item">
              <Link to="#">Customers</Link>
            </li>
            <li className="page-header__breadcrumb-item">
              <span>List</span>
            </li>
          </ul>
        </div>
        <div className="page-header__right">
          {actions.CR_C ? (
            <button className="btn">
              <Icon name="add" />
              Add Customer
            </button>
          ) : null}
        </div>
      </div>

      <div className="table-block-1">
        <div className="table-controls">
          <div className="table-controls__left">
            <div className="search-box search-box--filled">
              <IonIcon icon={searchOutline} className="search-box__icon" />
              <input
                className="input search-box__input"
                type="text"
                placeholder="Search by name / email...."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              {search ? (
                <span
                  className="search-box__clear"
                  onClick={(e) => setSearch("")}
                >
                  <IonIcon icon={closeOutline} />
                </span>
              ) : null}
            </div>
            <div className="table-controls__filters"></div>
          </div>
          <div className="table-controls__right">
            <button className="button" onClick={() => setShowFilters(true)}>
              <Icon name="filter" />
              Filters
            </button>
          </div>
        </div>
        <div>
          <div className="table-block">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>
                      <div className="th">ID</div>
                    </th>
                    <th>
                      <div className="th">Customer</div>
                    </th>
                    <th>
                      <div className="th">Telephone</div>
                    </th>
                    <th>
                      <div className="th">Wallet</div>
                    </th>
                    <th>
                      <div className="th">Overdraft</div>
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {!customers && loading ? (
                    <tr className="no-hover">
                      <td colSpan={6}>
                        <VerticalBarLoader sm />
                      </td>
                    </tr>
                  ) : null}
                  {customers && !customers.length ? (
                    <tr className="no-hover">
                      <td colSpan={6}>
                        <div className="tempty">
                          <Icon name="no-data" className="tempty__img" />
                          {numCustomers ? (
                            <p>
                              There are no customers in current filter.{" "}
                              <span
                                className="text-link"
                                onClick={() => setReload((rl) => !rl)}
                              >
                                Reload
                              </span>
                            </p>
                          ) : (
                            <p>
                              You are yet to add any customer.{" "}
                              {actions.CR_C ? (
                                <Link to="/add-customer" className="text-link">
                                  Add Customer
                                </Link>
                              ) : null}
                            </p>
                          )}
                        </div>
                      </td>
                    </tr>
                  ) : null}
                  {customers?.map((customer) => (
                    <tr key={customer._id}>
                      <td>#{customer._id.slice(0, 6)}</td>
                      <td>
                        <div className="tuser tuser--sm">
                          <img
                            src={customer.FullName}
                            alt=""
                            className="tuser__img"
                          />
                          <div className="tuser__main">
                            <p className="tuser__name">{customer.FullName}</p>
                            <p className="tuser__email">
                              {customer.EmailAddress}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <p className="titem">{customer.TelephoneNumber}</p>
                      </td>
                      <td>
                        <p className="titem">-&#8358;740.00</p>
                      </td>
                      <td>
                        {customer.Overdraft ? (
                          <p className="titem">
                            &#8358;{roundDP(customer.Overdraft, 2)}
                          </p>
                        ) : (
                          <p className="titem">N/A</p>
                        )}
                      </td>
                      <td>
                        <div className="tactions">
                          {actions.UP_C ? (
                            <button
                              className="taction"
                              onClick={() =>
                                navigate(`/edit-customer/${customer._id}`)
                              }
                            >
                              <IonIcon icon={createOutline} />
                            </button>
                          ) : null}
                          <button className="taction">
                            <IonIcon icon={eyeOutline} />
                          </button>
                          {actions.DL_C ? (
                            <button className="taction">
                              <IonIcon icon={trashOutline} />
                            </button>
                          ) : null}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {customers && loading ? <div className="line-loader"></div> : null}
          </div>
        </div>
        <div className="table-footer">
          <div className="division">
            {numRecords ? (
              <>
                Showing {start} - {end} of {numRecords}
              </>
            ) : null}
          </div>
          <div className="pagination-2">
            {numRecords ? (
              <>
                <button
                  className={cls(pageLoading === "prev-page" && "active")}
                  disabled={page === 1}
                  onClick={() => {
                    if (page === 1) return;

                    setPageLoading("prev-page");
                    setLoadPage(page - 1);
                  }}
                >
                  Previous
                </button>
                <button
                  className={cls(pageLoading === "next-page" && "active")}
                  disabled={!pagination || page === pagination}
                  onClick={() => {
                    if (page === pagination) return;

                    setPageLoading("next-page");
                    setLoadPage(page + 1);
                  }}
                >
                  Next
                </button>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Customers;
