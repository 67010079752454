import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import {
  cloudUploadOutline,
  eyeOutline,
  trashBinOutline,
} from "ionicons/icons";

import { tRootState } from "../../store";
import { tVendor } from "../../store/types/app.types";

import DashboardLayout from "../../layouts/Dashboard/Dashboard";

import Icon from "../../icons/Icon";

import useData from "../../hooks/useData/useData";

import Textarea from "../../components/Form/Textarea/Textarea";
import { NumberInput, TextInput } from "../../components/Form/Input/Input";

import ScrollLink from "../../components/Scrollable/ScrollLink";
import ScrollContent from "../../components/Scrollable/ScrollContent";
import Select from "../../components/Form/Select/Select";

const AddProduct = () => {
  const { allVendors } = useSelector((state: tRootState) => state.cache);

  const { fetchAllVendors } = useData();

  const [name, setName] = useState("");
  const [vendor, setVendor] = useState<tVendor | null>(null);
  const [code, setCode] = useState("");
  const [costPrice, setCostPrice] = useState<string | number>("");
  const [sellingPrice, setSellintPrice] = useState<string | number>("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    fetchAllVendors();
  }, [fetchAllVendors]);

  return (
    <DashboardLayout>
      <div className="page-header">
        <div className="page-header__left">
          <h3 className="page-header__heading">Add Product</h3>
          <ul className="page-header__breadcrumb">
            <li className="page-header__breadcrumb-item">
              <Link to="">Inventory</Link>
            </li>
            <li className="page-header__breadcrumb-item">
              <Link to="/products">Products</Link>
            </li>
            <li className="page-header__breadcrumb-item">
              <span>Add</span>
            </li>
          </ul>
        </div>
        <div className="page-header__right">
          <Link to="/products" className="btn">
            <IonIcon icon={eyeOutline} />
            View Products
          </Link>
        </div>
      </div>
      <div className="form-tab">
        <ul className="form-tab__main">
          <li className="form-tab__item">
            <ScrollLink
              className={({ active }) =>
                cls("form-tab__link", active && "active")
              }
              targetId="details"
            >
              <span> Details </span>
            </ScrollLink>
          </li>
          <li className="form-tab__item">
            <ScrollLink
              className={({ active }) =>
                cls("form-tab__link", active && "active")
              }
              targetId="image"
            >
              <span> Image </span>
            </ScrollLink>
          </li>
        </ul>
      </div>
      <div className="stepped-form-container">
        <ScrollContent className="stepped-form" id="details">
          <div className="stepped-form__header">
            <h3 className="stepped-form__heading">Details</h3>
            <p className="stepped-form__subheading">
              Edit your product description and necessary information from here
            </p>
          </div>
          <div className="stepped-form__main">
            <div className="stepped-form__grid">
              <div
                className={cls(
                  "fgroup",
                  vendor?.Type === "Internal" && "grid-full"
                )}
              >
                <label htmlFor="name-field" className="label">
                  Name
                </label>
                <TextInput
                  type="text"
                  placeholder="Product name"
                  bind={[name, setName]}
                />
              </div>
              <div className="fgroup">
                <label className="label">Vendor</label>
                <Select<tVendor>
                  type="single"
                  placeholder="Select vendor"
                  useSearch={true}
                  data={allVendors}
                  bind={[vendor, setVendor]}
                  transform={{ uniqueKey: "_id", displayKey: "Name" }}
                />
              </div>
              {vendor?.Type === "Internal" ? (
                <div className="fgroup">
                  <label htmlFor="code-field" className="label">
                    Code
                  </label>
                  <TextInput
                    type="text"
                    placeholder="Product code"
                    bind={[code, setCode]}
                  />
                </div>
              ) : null}
              <div className="fgroup">
                <label htmlFor="cost-price-field" className="label">
                  Cost Price
                </label>
                <NumberInput
                  label="&#8358;"
                  type="amount"
                  placeholder="10"
                  bind={[costPrice, setCostPrice]}
                />
              </div>
              <div className="fgroup">
                <label htmlFor="selling-price-field" className="label">
                  Selling Price
                </label>
                <NumberInput
                  label="&#8358;"
                  type="amount"
                  placeholder="10"
                  bind={[sellingPrice, setSellintPrice]}
                />
              </div>
              <div className="grid-full">
                <div className="fgroup">
                  <label htmlFor="description-field" className="label">
                    Description
                  </label>
                  <Textarea
                    rows={7}
                    placeholder="Product description"
                    bind={[description, setDescription]}
                  />
                </div>
              </div>
            </div>
          </div>
        </ScrollContent>
        <ScrollContent className="stepped-form" id="image">
          <div className="stepped-form__header">
            <h3 className="stepped-form__heading">Upload new product images</h3>
            <p className="stepped-form__subheading">
              Upload your product image gallery here
            </p>
          </div>
          <div className="stepped-form__main">
            <div className="file">
              <div className="file-input active">
                <input type="file" />
                <div className="file-input__left">
                  <Icon name="upload" />
                  Drop or select file
                </div>
                <div className="file-input__right">
                  <button className="button">
                    <IonIcon icon={trashBinOutline} />
                    Clear 1 files
                  </button>
                  <button className="btn">
                    <IonIcon icon={cloudUploadOutline} /> Add more files
                  </button>
                </div>
              </div>
              <div className="file-previews">
                <div className="file-preview file-preview--media">
                  <div className="file-preview__header">
                    <button className="file-preview__remove">
                      <IonIcon icon={trashBinOutline} />
                    </button>
                    <img
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-GL6p3-BFijueCriL8mQAY-vtrIiXuI4wfB_1VHxPlsw29PyAji81uINGrkeVxo6IOGs&usqp=CAU"
                      alt=""
                    />
                  </div>
                  <div className="file-preview__body">
                    <p className="file-preview__name">
                      <strong>IMAGE:</strong> dangote-cement.png
                    </p>
                    <span className="file-preview__size"> 314 kB </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ScrollContent>
      </div>
      <footer className="btn-footer">
        <button className="btn">Add Product</button>
      </footer>
    </DashboardLayout>
  );
};

export default AddProduct;
