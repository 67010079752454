import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (
      error.code === "ERR_BAD_REQUEST" &&
      [401, 403].includes(error.response.status)
    ) {
      if (error.response.status === 401) localStorage.removeItem("appState");
      window.location.assign(
        error.response.status === 401 ? "/login" : "/forbidden"
      );

      return new Promise((_, reject) => {
        const interval = window.setInterval(() => {
          reject(error);
          window.clearInterval(interval);
        }, 5000);
      });
    }

    return Promise.reject(error);
  }
);

export default instance;
