import { ChangeEvent, ReactNode, useEffect } from "react";

import validator from "validator";

const isNumber = (value: string) => {
  if (!value) return true;
  if ((value.match(/\./g)?.length || 0) > 1) return false;
  if (value.endsWith(".")) return true;

  const re = /^[0-9.\b]+$/;

  // if value is not blank, then test the regex
  return re.test(value);
};

export const roundDP = (value: number | string) => {
  const [bfDec, afDec] = value.toString().split(".");

  const containsDecimal = value.toString().indexOf(".");

  return `${Number(bfDec).toLocaleString()}${
    containsDecimal !== -1 ? "." : ""
  }${afDec?.length ? `${afDec}` : ""}`;
};

type tNumberInput = {
  label?: string | ReactNode;
  placeholder?: string;
  disabled?: boolean;
  type: "number" | "whole-number" | "amount";
  bind: [string | number, (value: string | number) => void];
  min?: number;
  max?: number;
  maxLength?: number;
};

type tTextInput = {
  label?: string | ReactNode;
  placeholder?: string;
  disabled?: boolean;
  type: "text" | "url" | "email";
  bind: [string, (value: string) => void];
  maxLength?: number;
};

export const NumberInput = ({
  label,
  placeholder,
  disabled,
  type,
  bind: [value, setValue],
  min,
  max,
  maxLength,
}: tNumberInput) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (disabled) return;

    const currentValue = e.target.value.replace(",", "");

    console.log(currentValue);

    if (!isNumber(currentValue)) return;

    if (min && +currentValue < min) return;
    if (max && +currentValue > max) return;

    if (maxLength && currentValue.length > maxLength) return;

    setValue(type === "whole-number" ? +currentValue : currentValue);
  };

  useEffect(() => {
    if (!min) return;

    setValue(type === "whole-number" ? +min : min);
  }, [type, min, setValue]);

  const Input = (
    <input
      type="text"
      className="input"
      placeholder={placeholder}
      value={type === "amount" && value ? roundDP(value) : value}
      onChange={handleChange}
      disabled={disabled}
    />
  );

  return label ? (
    <div className="igroup">
      <span> {label} </span>
      {Input}
    </div>
  ) : (
    Input
  );
};

export const TextInput = ({
  label,
  placeholder,
  disabled,
  type,
  bind: [value, setValue],
  maxLength,
}: tTextInput) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (disabled) return;

    const currentValue = e.target.value;

    if (maxLength && currentValue.length > maxLength) return;

    if (type === "url" && !validator.isURL(currentValue)) return;

    // if (type === "email" && !validator.isEmail(currentValue)) return;

    setValue(currentValue);
  };

  const Input = (
    <input
      type="text"
      className="input"
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
      disabled={disabled}
    />
  );

  return label ? (
    <div className="igroup">
      <span> {label} </span>
      {Input}
    </div>
  ) : (
    Input
  );
};

type tGNumberInput = {
  inputType: "number";
} & tNumberInput;

type tGTextInput = {
  inputType: "text";
} & tTextInput;

type tInput = tGNumberInput | tGTextInput;

const Input = (props: tInput) => {
  return props.inputType === "number" ? (
    <NumberInput {...props} />
  ) : (
    <TextInput {...props} />
  );
};

export default Input;
