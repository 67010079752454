export const roundDP = (
  value: number | string,
  len: number,
  noCommas?: boolean
) => {
  value = Number(value).toFixed(len);

  const [bfDec, afDec] = value.toString().split(".");

  let s = "";

  if (afDec) {
    s = afDec.slice(0, len);
  }

  for (let i = s.length; i < len; i++) {
    s += "0";
  }

  return noCommas ? `${bfDec}.${s}` : `${Number(bfDec).toLocaleString()}.${s}`;
};

export const getSkip = (page: number, division: number): number =>
  (page - 1) * division;

export const formatBytes = (bytes: number, decimals?: number) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024,
    dm = decimals || 2,
    sizes = ["Bytes", "kB", "mB", "gB", "tB", "pB", "eB", "zB", "yB"],
    i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

type Assert = <T>(data: T | null) => asserts data is T;
export const assertNotNull: Assert = <T>(data: T | null): asserts data is T => {
  if (data === null) {
    throw new Error(`null was found unexpectedly`);
  }
};
