import { useState } from "react";
import { Link } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import {
  arrowUndoOutline,
  caretDownOutline,
  chevronBackOutline,
  chevronForwardOutline,
  chevronUpOutline,
  closeOutline,
  createOutline,
  eyeOutline,
  trashOutline,
} from "ionicons/icons";

import DashboardLayout from "../../layouts/Dashboard/Dashboard";
import Icon from "../../icons/Icon";

const Transactions = () => {
  const [showFilters, setShowFilters] = useState(false);

  const toggleFilters = () => {
    setShowFilters((sf) => {
      if (sf) {
        document.querySelector("body")?.classList.remove("modal-open");
      } else {
        document.querySelector("body")?.classList.add("modal-open");
      }

      return !sf;
    });
  };

  return (
    <DashboardLayout>
      {showFilters ? (
        <div className="modal-overlay" onClick={() => toggleFilters()}></div>
      ) : null}
      <div className="page-header">
        <div className="page-header__left">
          <h3 className="page-header__heading">Transactions</h3>
          <ul className="page-header__breadcrumb">
            <li className="page-header__breadcrumb-item">
              <Link to="#">Home</Link>
            </li>
            <li className="page-header__breadcrumb-item">
              <Link to="#">Transactions</Link>
            </li>
            <li className="page-header__breadcrumb-item">
              <span>List</span>
            </li>
          </ul>
        </div>
        <div className="page-header__right">
          <button className="button active" onClick={() => toggleFilters()}>
            <Icon name="filter" />
            Hide Filters
          </button>
          <Link to="/add-transaction" className="btn">
            <Icon name="add" />
            Add Transaction
          </Link>
        </div>
      </div>
      <div className={cls("tfilter", showFilters && "open")}>
        <div className="tfilter__header">
          <h3 className="tfilter__heading">Table Filters</h3>
          <span className="tfilter__close" onClick={() => toggleFilters()}>
            <IonIcon icon={closeOutline} />
          </span>
        </div>
        <div className="tfilter__body"></div>
        <div className="tfilter__footer">
          <button className="btn">Show Results</button>
        </div>
      </div>
      <div className="table-block-1">
        {/* <div className="table-controls">
          <div className="table-controls__left">
            <div className="table-controls__filters"></div>
          </div>
        </div> */}
        <div>
          <div className="table-block">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th></th>
                    <th>
                      <div className="th">ID</div>
                    </th>
                    <th>
                      <div className="th">Type</div>
                    </th>
                    <th>
                      <div className="th">Origin</div>
                    </th>
                    <th>
                      <div className="th">Destination</div>
                    </th>
                    <th>
                      <div className="th">
                        Date
                        <span>
                          <IonIcon icon={caretDownOutline} />
                        </span>
                      </div>
                    </th>
                    <th>
                      <div className="th th--right">
                        Income
                        <span>
                          <IonIcon icon={caretDownOutline} />
                        </span>
                      </div>
                    </th>
                    <th>
                      <div className="th th--right">
                        Expense
                        <span>
                          <IonIcon icon={caretDownOutline} />
                        </span>
                      </div>
                    </th>
                    <th>
                      <div className="th">Status</div>
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <button className="texpand open">
                        <IonIcon icon={chevronUpOutline} />
                      </button>
                    </td>
                    <td>#28361</td>
                    <td>Sale</td>
                    <td>
                      <div className="tuser tuser--sm">
                        <div className="tuser__main">
                          <p className="tuser__name">Smabirm Lagos</p>
                          <p className="tuser__email">Branch</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="tuser tuser--sm">
                        <img
                          src="https://isomorphic-furyroad.s3.amazonaws.com/public/avatars/avatar-04.webp"
                          alt=""
                          className="tuser__img"
                        />
                        <div className="tuser__main">
                          <p className="tuser__name">Guillermo McLaughlin</p>
                          <p className="tuser__email">Customer</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="tdate">
                        <p className="tdate__date">November 19, 2018</p>
                        <p className="tdate__time">9:26 AM</p>
                      </div>
                    </td>
                    <td>
                      <p className="align-right titem">&#8358;740.00</p>
                    </td>
                    <td>
                      <p className="align-right titem">&#8358;100.00</p>
                    </td>
                    <td>
                      <span className="tbadge tbadge--success">Confirmed</span>
                    </td>
                    <td>
                      <div className="tactions">
                        <button className="taction">
                          <IonIcon icon={eyeOutline} />
                        </button>
                        <button className="taction">
                          <IonIcon icon={createOutline} />
                        </button>
                        <button className="taction">
                          <IonIcon icon={trashOutline} />
                        </button>
                      </div>
                    </td>
                  </tr>
                  <tr className="no-hover">
                    <td colSpan={10} className="py-0">
                      <div>
                        <table className="suborder-table">
                          <tbody>
                            <tr>
                              <td>
                                <div className="tproduct">
                                  <img
                                    src="https://egoleshopping.com/images/detailed/12/sugar.jpg"
                                    alt=""
                                    className="tproduct__img"
                                  />
                                  <div className="tproduct__main">
                                    <p className="tproduct__name">
                                      Dangote Sugar
                                    </p>
                                    <p className="tproduct__category">
                                      Product
                                    </p>
                                    <div className="tproduct__unit-price">
                                      Unit Price: &#8358;410.00
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="tquantity">
                                  <IonIcon icon={closeOutline} /> 2
                                </div>
                              </td>
                              <td>
                                <div className="titem align-right">
                                  &#8358;820
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div className="tproduct">
                                  <img
                                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-GL6p3-BFijueCriL8mQAY-vtrIiXuI4wfB_1VHxPlsw29PyAji81uINGrkeVxo6IOGs&usqp=CAU"
                                    alt=""
                                    className="tproduct__img"
                                  />
                                  <div className="tproduct__main">
                                    <p className="tproduct__name">
                                      Dangote Cement
                                    </p>
                                    <p className="tproduct__category">
                                      Product
                                    </p>
                                    <div className="tproduct__unit-price">
                                      Unit Price: &#8358;342.00
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="tquantity">
                                  <IonIcon icon={closeOutline} /> 3
                                </div>
                              </td>
                              <td>
                                <div className="titem align-right">
                                  &#8358;1026
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div className="tproduct">
                                  <img
                                    src="https://springs.com.pk/cdn/shop/files/8961014018196.jpg?v=1691669850"
                                    alt=""
                                    className="tproduct__img"
                                  />
                                  <div className="tproduct__main">
                                    <p className="tproduct__name">Custard</p>
                                    <p className="tproduct__category">
                                      Product
                                    </p>
                                    <div className="tproduct__unit-price">
                                      Unit Price: &#8358;948.00
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="tquantity">
                                  <IonIcon icon={closeOutline} /> 1
                                </div>
                              </td>
                              <td>
                                <div className="titem align-right">
                                  &#8358;948
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <button className="texpand">
                        <IonIcon icon={chevronUpOutline} />
                      </button>
                    </td>
                    <td>#28361</td>
                    <td>Purchase</td>
                    <td>
                      <div className="tuser tuser--sm">
                        <div className="tuser__main">
                          <p className="tuser__name">Dangote</p>
                          <p className="tuser__email">Manufacturer</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="tuser tuser--sm">
                        <div className="tuser__main">
                          <p className="tuser__name">Smabirm Lagos</p>
                          <p className="tuser__email">Branch</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="tdate">
                        <p className="tdate__date">November 1, 2018</p>
                        <p className="tdate__time">2:26 AM</p>
                      </div>
                    </td>
                    <td>
                      <p className="align-right titem">ASSET</p>
                    </td>
                    <td>
                      <p className="align-right titem">&#8358;50.00</p>
                    </td>
                    <td>
                      <span className="tbadge tbadge--success">Confirmed</span>
                    </td>
                    <td>
                      <div className="tactions">
                        <button className="taction">
                          <IonIcon icon={eyeOutline} />
                        </button>
                        <button className="taction">
                          <IonIcon icon={createOutline} />
                        </button>
                        <button className="taction">
                          <IonIcon icon={trashOutline} />
                        </button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <button className="texpand">
                        <IonIcon icon={chevronUpOutline} />
                      </button>
                    </td>
                    <td>#28361</td>
                    <td>Transport</td>
                    <td>
                      <div className="tuser tuser--sm">
                        <div className="tuser__main">
                          <p className="tuser__name">Smabirm Lagos</p>
                          <p className="tuser__email">Branch</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="tuser tuser--sm">
                        <div className="tuser__main">
                          <p className="tuser__name">Smabirm Abuja</p>
                          <p className="tuser__email">Branch</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="tdate">
                        <p className="tdate__date">April 3, 2019</p>
                        <p className="tdate__time">2:54 AM</p>
                      </div>
                    </td>
                    <td>
                      <p className="align-right titem">N/A</p>
                    </td>
                    <td>
                      <p className="align-right titem">&#8358;260.00</p>
                    </td>
                    <td>
                      <span className="tbadge tbadge--info">
                        Out For Delivery
                      </span>
                    </td>
                    <td>
                      <div className="tactions">
                        <button className="taction">
                          <IonIcon icon={eyeOutline} />
                        </button>
                        <button className="taction">
                          <IonIcon icon={createOutline} />
                        </button>
                        <button className="taction">
                          <IonIcon icon={trashOutline} />
                        </button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <button className="texpand">
                        <IonIcon icon={chevronUpOutline} />
                      </button>
                    </td>
                    <td>#28361</td>
                    <td>Sale</td>
                    <td>
                      <div className="tuser tuser--sm">
                        <div className="tuser__main">
                          <p className="tuser__name">Smabirm Abuja</p>
                          <p className="tuser__email">Branch</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="tuser tuser--sm">
                        <img
                          src="https://isomorphic-furyroad.s3.amazonaws.com/public/avatars/avatar-04.webp"
                          alt=""
                          className="tuser__img"
                        />
                        <div className="tuser__main">
                          <p className="tuser__name">Guillermo McLaughlin</p>
                          <p className="tuser__email">Customer</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="tdate">
                        <p className="tdate__date">November 19, 2018</p>
                        <p className="tdate__time">9:26 AM</p>
                      </div>
                    </td>
                    <td>
                      <p className="align-right titem">&#8358;740.00</p>
                    </td>
                    <td>
                      <p className="align-right titem">&#8358;65.50</p>
                    </td>
                    <td>
                      <span className="tbadge tbadge--success">Confirmed</span>
                    </td>
                    <td>
                      <div className="tactions">
                        <button className="taction">
                          <IonIcon icon={eyeOutline} />
                        </button>
                        <button className="taction">
                          <IonIcon icon={createOutline} />
                        </button>
                        <button className="taction">
                          <IonIcon icon={trashOutline} />
                        </button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <button className="texpand">
                        <IonIcon icon={chevronUpOutline} />
                      </button>
                    </td>
                    <td>#28361</td>
                    <td>
                      <div className="tflex">
                        <IonIcon icon={arrowUndoOutline} /> Sale
                      </div>
                    </td>
                    <td>
                      <div className="tuser tuser--sm">
                        <img
                          src="https://isomorphic-furyroad.s3.amazonaws.com/public/avatars/avatar-04.webp"
                          alt=""
                          className="tuser__img"
                        />
                        <div className="tuser__main">
                          <p className="tuser__name">Guillermo McLaughlin</p>
                          <p className="tuser__email">Customer</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="tuser tuser--sm">
                        <div className="tuser__main">
                          <p className="tuser__name">Smabirm Abuja</p>
                          <p className="tuser__email">Branch</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="tdate">
                        <p className="tdate__date">November 19, 2018</p>
                        <p className="tdate__time">9:26 AM</p>
                      </div>
                    </td>
                    <td>
                      <p className="align-right titem">ASSET</p>
                    </td>
                    <td>
                      <p className="align-right titem">&#8358;350.00</p>
                    </td>
                    <td>
                      <span className="tbadge tbadge--success">Confirmed</span>
                    </td>
                    <td>
                      <div className="tactions">
                        <button className="taction">
                          <IonIcon icon={eyeOutline} />
                        </button>
                        <button className="taction">
                          <IonIcon icon={createOutline} />
                        </button>
                        <button className="taction">
                          <IonIcon icon={trashOutline} />
                        </button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <button className="texpand">
                        <IonIcon icon={chevronUpOutline} />
                      </button>
                    </td>
                    <td>#28361</td>
                    <td>Sale</td>
                    <td>
                      <div className="tuser tuser--sm">
                        <div className="tuser__main">
                          <p className="tuser__name">Smabirm Lagos</p>
                          <p className="tuser__email">Branch</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="tuser tuser--sm">
                        <img
                          src="https://isomorphic-furyroad.s3.amazonaws.com/public/avatars/avatar-01.webp"
                          alt=""
                          className="tuser__img"
                        />
                        <div className="tuser__main">
                          <p className="tuser__name">Orville Kub</p>
                          <p className="tuser__email">Customer</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="tdate">
                        <p className="tdate__date">November 1, 2018</p>
                        <p className="tdate__time">2:26 AM</p>
                      </div>
                    </td>
                    <td>
                      <p className="align-right titem">&#8358;567.00</p>
                    </td>
                    <td>
                      <p className="align-right titem">&#8358;8.50</p>
                    </td>
                    <td>
                      <span className="tbadge tbadge--info">
                        Out For Delivery
                      </span>
                    </td>
                    <td>
                      <div className="tactions">
                        <button className="taction">
                          <IonIcon icon={eyeOutline} />
                        </button>
                        <button className="taction">
                          <IonIcon icon={createOutline} />
                        </button>
                        <button className="taction">
                          <IonIcon icon={trashOutline} />
                        </button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <button className="texpand">
                        <IonIcon icon={chevronUpOutline} />
                      </button>
                    </td>
                    <td>#28361</td>
                    <td>Sale</td>
                    <td>
                      <div className="tuser tuser--sm">
                        <div className="tuser__main">
                          <p className="tuser__name">Smabirm Ibadan</p>
                          <p className="tuser__email">Branch</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="tuser tuser--sm">
                        <img
                          src="https://isomorphic-furyroad.s3.amazonaws.com/public/avatars/avatar-13.webp"
                          alt=""
                          className="tuser__img"
                        />
                        <div className="tuser__main">
                          <p className="tuser__name">Melanie Lehner</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="tdate">
                        <p className="tdate__date">April 3, 2019</p>
                        <p className="tdate__time">2:54 AM</p>
                      </div>
                    </td>
                    <td>
                      <p className="align-right titem">&#8358;260.00</p>
                    </td>
                    <td>
                      <p className="align-right titem">&#8358;15.65</p>
                    </td>
                    <td>
                      <span className="tbadge tbadge--success">Confirmed</span>
                    </td>
                    <td>
                      <div className="tactions">
                        <button className="taction">
                          <IonIcon icon={eyeOutline} />
                        </button>
                        <button className="taction">
                          <IonIcon icon={createOutline} />
                        </button>
                        <button className="taction">
                          <IonIcon icon={trashOutline} />
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="table-footer">
          <div className="division">Showing 1 to 20 of 50 results</div>
          <div className="pagination">
            <button>
              <IonIcon icon={chevronBackOutline} />
            </button>
            <button className="active">1</button>
            <button>2</button>
            <button>3</button>
            <button>
              <IonIcon icon={chevronForwardOutline} />
            </button>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Transactions;
