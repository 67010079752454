import { useState } from "react";
import { useSelector } from "react-redux";

import api_client from "../../api/client";

import { tRootState } from "../../store";

import { assertNotNull } from "../../utils/func";

const useLogout = (): [boolean, () => void] => {
  const accessToken = useSelector(
    (state: tRootState) => state.auth.accessToken
  );

  assertNotNull(accessToken);

  const [loading, setLoading] = useState(false);

  const logoutHandler = () => {
    setLoading(true);

    api_client({
      url: "/admins/logout",
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .catch((err) => {
        // do nothing
      })
      .finally(() => {
        localStorage.removeItem("appState");
        window.location.assign("/login");
      });
  };

  return [loading, logoutHandler];
};

export default useLogout;
