import { useState } from "react";
import { Link } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import {
  cloudUploadOutline,
  eyeOutline,
  trashBinOutline,
} from "ionicons/icons";

import DashboardLayout from "../../layouts/Dashboard/Dashboard";

import Icon from "../../icons/Icon";

import Textarea from "../../components/Form/Textarea/Textarea";
import { NumberInput, TextInput } from "../../components/Form/Input/Input";

import ScrollLink from "../../components/Scrollable/ScrollLink";
import ScrollContent from "../../components/Scrollable/ScrollContent";

const AddExpense = () => {
  const [name, setName] = useState("");
  const [price, setPrice] = useState<string | number>("");
  const [description, setDescription] = useState("");

  return (
    <DashboardLayout>
      <div className="page-header">
        <div className="page-header__left">
          <h3 className="page-header__heading">Add Expense</h3>
          <ul className="page-header__breadcrumb">
            <li className="page-header__breadcrumb-item">
              <Link to="">Inventory</Link>
            </li>
            <li className="page-header__breadcrumb-item">
              <Link to="/expenses">Expenses</Link>
            </li>
            <li className="page-header__breadcrumb-item">
              <span>Add</span>
            </li>
          </ul>
        </div>
        <div className="page-header__right">
          <Link to="/expenses" className="btn">
            <IonIcon icon={eyeOutline} />
            View Expenses
          </Link>
        </div>
      </div>
      <div className="form-tab">
        <ul className="form-tab__main">
          <li className="form-tab__item">
            <ScrollLink
              className={({ active }) =>
                cls("form-tab__link", active && "active")
              }
              targetId="details"
            >
              <span> Details </span>
            </ScrollLink>
          </li>
          <li className="form-tab__item">
            <ScrollLink
              className={({ active }) =>
                cls("form-tab__link", active && "active")
              }
              targetId="image"
            >
              <span> Image </span>
            </ScrollLink>
          </li>
        </ul>
      </div>
      <div className="stepped-form-container">
        <ScrollContent className="stepped-form" id="details">
          <div className="stepped-form__header">
            <h3 className="stepped-form__heading">Details</h3>
            <p className="stepped-form__subheading">
              Edit your expense description and necessary information from here
            </p>
          </div>
          <div className="stepped-form__main">
            <div className="stepped-form__grid">
              <div className="fgroup">
                <label htmlFor="name-field" className="label">
                  Name
                </label>
                <TextInput
                  type="text"
                  placeholder="Expense name"
                  bind={[name, setName]}
                />
              </div>
              <div className="fgroup">
                <label htmlFor="price-field" className="label">
                  Price
                </label>
                <NumberInput
                  label="&#8358;"
                  type="amount"
                  placeholder="10"
                  bind={[price, setPrice]}
                />
              </div>
              <div className="grid-full">
                <div className="fgroup">
                  <label htmlFor="description-field" className="label">
                    Description
                  </label>
                  <Textarea
                    rows={7}
                    placeholder="Expense description"
                    bind={[description, setDescription]}
                  />
                </div>
              </div>
            </div>
          </div>
        </ScrollContent>
        <ScrollContent className="stepped-form" id="image">
          <div className="stepped-form__header">
            <h3 className="stepped-form__heading">Proof</h3>
            <p className="stepped-form__subheading">
              Upload receipt or proof of expense being paid for
            </p>
          </div>
          <div className="stepped-form__main">
            <div className="file">
              <div className="file-input active">
                <input type="file" />
                <div className="file-input__left">
                  <Icon name="upload" />
                  Drop or select file
                </div>
                <div className="file-input__right">
                  <button className="button">
                    <IonIcon icon={trashBinOutline} />
                    Clear 1 files
                  </button>
                  <button className="btn">
                    <IonIcon icon={cloudUploadOutline} /> Add more files
                  </button>
                </div>
              </div>
              <div className="file-previews">
                <div className="file-preview file-preview--media">
                  <div className="file-preview__header">
                    <button className="file-preview__remove">
                      <IonIcon icon={trashBinOutline} />
                    </button>
                    <img
                      src="https://docs.oracle.com/cd/E92727_01/webhelp/Content/obdx/introduction/ereceipt/e_reciept.png"
                      alt=""
                    />
                  </div>
                  <div className="file-preview__body">
                    <p className="file-preview__name">
                      <strong>IMAGE:</strong> receipt.png
                    </p>
                    <span className="file-preview__size"> 314 kB </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ScrollContent>
      </div>
      <footer className="btn-footer">
        <button className="btn">Add Expense</button>
      </footer>
    </DashboardLayout>
  );
};

export default AddExpense;
