import { FormEvent, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { IonIcon } from "@ionic/react";
import { arrowForwardOutline, eyeOffOutline, eyeOutline } from "ionicons/icons";

import api_client from "../../api/client";

import { login } from "../../store/authReducer";

import { withUnAuth } from "../../hoc/withAuth/withAuth";

import useAlert from "../../hooks/useAlert/useAlert";

import Auth from "../../layouts/Auth/Auth";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");

  const [password, setPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);

  const submitBtnRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);

  const [message, setMessage, clearMessage] = useAlert();

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!email || !password) return setMessage("warning", "Fill in all fields");

    const submitBtn = submitBtnRef.current;
    const currentHTML = submitBtn.innerHTML;

    submitBtn.setAttribute("disabled", "disabled");
    submitBtn.innerHTML = `<span class="fas fa-spinner fa-spin"></span> Signing In`;

    api_client({
      url: "/admins/login",
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: { EmailAddress: email, Password: password },
    })
      .then((res) => {
        dispatch(
          login({
            accessToken: res.data.data.accessToken,
            adminDetails: res.data.data.admin,
          })
        );

        navigate("/");
      })
      .catch((err) => {
        if (err.code === "ERR_BAD_REQUEST") {
          setMessage("warning", err.response.data.message);
        } else {
          setMessage("error", err.message);
        }
      })
      .finally(() => {
        if (!submitBtn) return;
        submitBtn.innerHTML = currentHTML;
        submitBtn.removeAttribute("disabled");
      });
  };

  useEffect(() => {
    clearMessage();
  }, [email, password, clearMessage]);

  return (
    <Auth>
      <div className="auth__header">
        <h1 className="auth__heading">
          Welcome back! Please <br />
          Sign in to continue.
        </h1>
      </div>
      <form className="auth__form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="email-field" className="form-label">
            Email
          </label>
          <input
            type="email"
            className="form-input"
            placeholder="Enter your email"
            id="email-field"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password-field" className="form-label">
            Password
          </label>
          <div className="form-input-group">
            <input
              type={showPassword ? "text" : "password"}
              className="form-input"
              placeholder="Enter your password"
              id="password-field"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <button type="button" onClick={() => setShowPassword((sp) => !sp)}>
              <IonIcon icon={showPassword ? eyeOffOutline : eyeOutline} />
            </button>
          </div>
        </div>
        <div className="text-right">
          <Link to="/forgot-password" className="link">
            Forgot Password?
          </Link>
        </div>
        <div className="auth__form-footer">
          {message}
          <button className="btn btn--md btn--dark" ref={submitBtnRef}>
            Sign In
            <IonIcon icon={arrowForwardOutline} />
          </button>
        </div>
      </form>
    </Auth>
  );
};

export default withUnAuth(Login);
