import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import api_client from "../../api/client";

import { tRootState } from "../../store";
import {
  updateAllVendors,
  updateCountries,
  updateVendorsCache,
} from "../../store/cacheReducer";

import { updateAdminDetails } from "../../store/authReducer";
import { tVendorDetails } from "../../store/types/app.types";

const useData = () => {
  const dispatch = useDispatch();

  const accessToken = useSelector(
    (state: tRootState) => state.auth.accessToken
  );

  // No unhandled error

  // fetch profile
  const fetchProfile = useCallback(() => {
    return new Promise((resolve, reject) => {
      api_client({
        method: "GET",
        url: "/admins/profile",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => {
          dispatch(updateAdminDetails(res.data.data));

          resolve("Profile fetched successfully");
        })
        .catch((err) => {
          resolve("Error fetching profile");
        });
    });
  }, [accessToken, dispatch]);

  // fetch countries
  const fetchCountries = useCallback(() => {
    return new Promise((resolve, reject) => {
      api_client({
        method: "GET",
        url: "/countries",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => {
          dispatch(updateCountries(res.data.data));

          resolve("Countries fetched successfully");
        })
        .catch((err) => {
          resolve("Error fetching countries");
        });
    });
  }, [accessToken, dispatch]);

  // fetch vendors
  const fetchAllVendors = useCallback(() => {
    return new Promise((resolve, reject) => {
      api_client({
        method: "GET",
        url: "/vendors?noPagination=true",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => {
          dispatch(updateAllVendors(res.data.data.vendors));

          resolve("Vendors fetched successfully");
        })
        .catch((err) => {
          resolve("Error fetching vendors");
        });
    });
  }, [accessToken, dispatch]);

  // fetch vendor
  const fetchVendor = useCallback(
    (vendorId: string) => {
      return new Promise<tVendorDetails>((resolve, reject) => {
        api_client({
          url: `/vendots/${vendorId}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
          .then((res) => {
            dispatch(updateVendorsCache(res.data.data));
            resolve(res.data.product);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    [dispatch, accessToken]
  );

  return {
    fetchProfile,
    fetchCountries,
    fetchAllVendors,
    fetchVendor,
  };
};

export default useData;
