import { Link } from "react-router-dom";

import { IonIcon } from "@ionic/react";
import { arrowForwardOutline } from "ionicons/icons";

import Auth from "../../layouts/Auth/Auth";

const ForgotPassword = () => {
  return (
    <Auth>
      <div className="auth__header">
        <h1 className="auth__heading">Forgot your password?</h1>
        <p className="auth__sub-heading">
          Kindly fill in your email address a password reset link and OTP code
          would be sent to you to reset your account password
        </p>
      </div>
      <form className="auth__form">
        <div className="form-group">
          <label htmlFor="email-field" className="form-label">
            Email
          </label>
          <input
            type="text"
            className="form-input"
            placeholder="Enter your email"
            id="email-field"
          />
        </div>
        <div className="auth__form-footer">
          <button className="btn btn--md btn--dark">
            Continue <IonIcon icon={arrowForwardOutline} />
          </button>
        </div>
      </form>
      <div className="auth__footer">
        Remember your password? <Link to="/login">Back to Login</Link>
      </div>
    </Auth>
  );
};

export default ForgotPassword;
