import { useEffect } from "react";
import cls from "classnames";

const ErrorModal = ({
  errorMsg,
  closeHandler,
}: {
  errorMsg: string;
  closeHandler: () => void;
}) => {
  useEffect(() => {
    if (errorMsg) {
      document.querySelector("body")?.classList.add("modal-open");
    } else {
      document.querySelector("body")?.classList.remove("modal-open");
    }
  }, [errorMsg]);

  return (
    <>
      <div className={cls("success-modal", errorMsg && "open")}>
        {errorMsg ? (
          <>
            <div className="icon warning">
              <span className="body"></span>
              <span className="dot"></span>
            </div>
            <p className="text-center">{errorMsg}</p>
            <div className="success-modal__btns">
              <button
                className="button"
                onClick={() => {
                  closeHandler();
                }}
              >
                Close
              </button>
            </div>
          </>
        ) : null}
      </div>
      <div className="modal-overlay" onClick={() => closeHandler()}></div>
    </>
  );
};

export default ErrorModal;
