import { useEffect, useState } from "react";
import cls from "classnames";
import { IonIcon } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import { SimpleSelect } from "../../../components/Form/Select/Select";
import { NumberInput } from "../../../components/Form/Input/Input";

export type tCustomersFiltersData = {
  minWallet?: string;
  maxWallet?: string;
  overdraft?: string;
  minOverdraft?: string;
  maxOverdraft?: string;
};

const CustomersFilters = ({
  open,
  handler,
}: {
  open: boolean;
  handler: (data: tCustomersFiltersData) => void;
}) => {
  const [minWallet, setMinWallet] = useState<number | string>("");
  const [maxWallet, setMaxWallet] = useState<number | string>("");

  const overdraftOptions = ["All", "Yes", "No"];
  const [overdraft, setOverdraft] = useState("All");

  const [minOverdraft, setMinOverdraft] = useState<string | number>("");
  const [maxOverdraft, setMaxOverdraft] = useState<string | number>("");

  const handleClose = () => {
    let data: tCustomersFiltersData = {};

    if (minWallet) data.minWallet = minWallet.toString();
    if (maxWallet) data.maxWallet = maxWallet.toString();

    if (overdraft !== "All") {
      data.overdraft = "true";

      if (minOverdraft) data.minOverdraft = minOverdraft.toString();
      if (maxOverdraft) data.maxOverdraft = maxOverdraft.toString();
    }

    handler(data);
  };

  const clearData = () => {
    setMinWallet("");
    setMaxWallet("");
    setOverdraft("All");
    setMinOverdraft("");
    setMaxOverdraft("");

    handler({});
  };

  useEffect(() => {
    if (open) {
      document.querySelector("body")?.classList.add("modal-open");
    } else {
      document.querySelector("body")?.classList.remove("modal-open");
    }
  }, [open]);

  return (
    <>
      {open ? (
        <div className="modal-overlay" onClick={() => handleClose()}></div>
      ) : null}

      <div className={cls("tfilter", open && "open")}>
        <div className="tfilter__header">
          <h3 className="tfilter__heading">
            <span className="tfilter__close" onClick={() => handleClose()}>
              <IonIcon icon={closeOutline} />
            </span>{" "}
            Table Filters
          </h3>
          <span className="text-link" onClick={() => clearData()}>
            Clear filter
          </span>
        </div>
        <div className="tfilter__body">
          <div className="fgroup">
            <label className="label">Wallet</label>
            <div className="range-group">
              <NumberInput
                label="&#8358;"
                type="amount"
                placeholder="Min. wallet balance"
                bind={[minWallet, setMinWallet]}
              />
              <div className="range-group__divider"></div>
              <NumberInput
                label="&#8358;"
                type="amount"
                placeholder="Max. wallet balance"
                bind={[maxWallet, setMaxWallet]}
              />
            </div>
          </div>
          <div className="fgroup">
            <label className="label">Has Overdraft?</label>
            <SimpleSelect
              type="single"
              placeholder="Select..."
              useSearch={false}
              bind={[overdraft, setOverdraft]}
              data={overdraftOptions}
            />
          </div>
          <div className="fgroup">
            <label className="label">Overdraft</label>
            <div className="range-group">
              <NumberInput
                label="&#8358;"
                type="amount"
                placeholder="Min. overdraft"
                bind={[minOverdraft, setMinOverdraft]}
              />
              <div className="range-group__divider"></div>
              <NumberInput
                label="&#8358;"
                type="amount"
                placeholder="Max. overdraft"
                bind={[maxOverdraft, setMaxOverdraft]}
              />
            </div>
          </div>
        </div>
        <div className="tfilter__footer">
          <button className="btn" onClick={() => handleClose()}>
            Show Results
          </button>
        </div>
      </div>
    </>
  );
};

export default CustomersFilters;
