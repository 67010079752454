import { Navigate } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";

import { tRootState } from "../../store";

import useData from "../../hooks/useData/useData";

export const withUnAuth =
  <P extends object>(Component: React.ComponentType) =>
  (props: P) => {
    const { accessToken, adminDetails } = useSelector(
      (state: tRootState) => state.auth
    );

    if (accessToken && adminDetails) return <Navigate to="/" />;

    return <Component {...props} />;
  };

const withAuth =
  <P extends object>(Component: React.ComponentType) =>
  (props: P) => {
    const { accessToken, adminDetails } = useSelector(
      (state: tRootState) => state.auth
    );

    const { fetchProfile } = useData();

    useEffect(() => {
      fetchProfile();
    }, [fetchProfile]);

    if (!accessToken || !adminDetails) return <Navigate to="/login" />;

    return <Component {...props} />;
  };

export default withAuth;
