import { IonIcon } from "@ionic/react";
import { caretDownOutline, chevronUpOutline } from "ionicons/icons";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import cls from "classnames";
import validator from "validator";

export type tTelephoneInputCountry = {
  isoCode: string;
  name: string;
  flag: string;
  telephoneCode: string;
};

export type tSingleTelephoneInputCountry = tTelephoneInputCountry | null;

export type tTelephoneInputCountries = tTelephoneInputCountry[];

type tTelephoneInput = {
  placeholder?: string;
  disabled?: boolean;
  countries: tTelephoneInputCountries;
  countryBind: [
    tTelephoneInputCountry | null,
    (country: tTelephoneInputCountry | null) => void
  ];
  telBind: [string, React.Dispatch<React.SetStateAction<string>>];
};

const TelephoneInput = ({
  placeholder,
  disabled,
  countries,
  countryBind: [country, setCountry],
  telBind: [telephone, setTelephone],
}: tTelephoneInput) => {
  const selectElement = useRef<HTMLDivElement | null>(null);

  const [openSelect, setOpenSelect] = useState(false);

  const [localTel, setLocalTel] = useState(telephone);

  const [search, setSearch] = useState("");

  const [inputFocused, setInputFocused] = useState(false);

  const telephoneChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (disabled) return;

    const value = e.target.value.replace(" ", "");

    if (country && value.length <= country.telephoneCode.length) {
      setCountry(null);
      setLocalTel("");

      return;
    }

    const telPart = value.slice(country?.telephoneCode ? 1 : 0);

    if (!value || validator.isNumeric(telPart)) setLocalTel(value);
  };

  const inputRef = useRef<HTMLInputElement>({} as HTMLInputElement);

  const currentCountry = useRef(country);

  useEffect(() => {
    if (!country?.telephoneCode) return setTelephone(localTel);

    if (localTel === country.telephoneCode) return setTelephone("");

    setTelephone(localTel);
  }, [country?.telephoneCode, localTel, setTelephone]);

  useEffect(() => {
    if (!country?.telephoneCode) return;

    if (currentCountry.current) {
      setLocalTel((tel) =>
        tel.replace(
          currentCountry.current!.telephoneCode,
          country.telephoneCode
        )
      );
    } else {
      setLocalTel((tel) =>
        tel.indexOf(country.telephoneCode) !== -1
          ? tel
          : `${country.telephoneCode}${tel}`
      );
    }
  }, [country?.telephoneCode]);

  useEffect(() => {
    selectElement.current?.addEventListener("focusout", function (event) {
      // Check if the newly focused element is outside the popup and closes it
      if (
        !this.contains(event.relatedTarget as Node | null) &&
        event.relatedTarget !== selectElement.current
      ) {
        setOpenSelect(false);
        setInputFocused(false);
      }
    });
  }, []);

  useEffect(() => {
    if (!localTel && country?.telephoneCode) setLocalTel(country.telephoneCode);
  }, [localTel, country?.telephoneCode]);

  return (
    <div className="telephone" ref={selectElement}>
      <div className="telephone-input">
        {country ? (
          <button
            className="telephone-input__toggle-country-select"
            onClick={() => setOpenSelect((os) => !os)}
            type="button"
          >
            <img src={country.flag} alt="" />
            <IonIcon icon={caretDownOutline} />
          </button>
        ) : null}
        <input
          type="text"
          className="input"
          value={
            country
              ? localTel.replace(
                  country.telephoneCode,
                  `${country.telephoneCode} `
                )
              : localTel
          }
          placeholder={placeholder}
          onChange={telephoneChangeHandler}
          disabled={disabled}
          style={!country ? { paddingLeft: "1.2rem" } : {}}
          onFocus={() => setInputFocused(true)}
          // onBlur={() => setInputFocused(false)}
          ref={inputRef}
        />
      </div>
      <div className="select">
        <div
          className={cls(
            "select-input",
            ((inputFocused && !country) || openSelect) && "open"
          )}
        >
          <input type="text" className="input" placeholder="Select..." />
          <span className="select-input__icon-box">
            <IonIcon icon={chevronUpOutline} className="select-input__icon" />
          </span>
        </div>
        <div className="select-box">
          <div className="select-box__input-block">
            <input
              type="text"
              className="input input--sm"
              placeholder="Search..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <ul className="select-box__list" tabIndex={0}>
            {countries
              .filter((dt) => new RegExp(search, "i").test(dt.name))
              .map((dt, i) => {
                const isChecked = dt.isoCode === country?.isoCode;

                return (
                  <li
                    className={cls(
                      "select-box__list-item",
                      isChecked && "active"
                    )}
                    key={i}
                    onClick={() => {
                      currentCountry.current = country;
                      setCountry(dt);
                      setOpenSelect(false);
                      inputRef.current.focus();
                    }}
                  >
                    <div className="telephone__list-item">
                      <img src={dt.flag} alt="" />
                      {dt.name} <span>{dt.telephoneCode}</span>
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TelephoneInput;
