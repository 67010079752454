import { ReactNode, useEffect } from "react";
import cls from "classnames";

export type tSuccess = {
  title: string;
  message: string;
  controls?: ReactNode;
  closeHandler?: () => void;
} | null;

const SuccessModal = ({ success }: { success: tSuccess }) => {
  useEffect(() => {
    if (success) {
      document.querySelector("body")?.classList.add("modal-open");
    } else {
      document.querySelector("body")?.classList.remove("modal-open");
    }
  }, [success]);

  return (
    <>
      <div className={cls("success-modal", success && "open")}>
        {success ? (
          <>
            <div className="success-checkmark">
              <div className="check-icon">
                <span className="icon-line line-tip"></span>
                <span className="icon-line line-long"></span>
                <div className="icon-circle"></div>
                <div className="icon-fix"></div>
              </div>
            </div>
            <h3>{success.title}</h3>
            <p>{success.message}</p>
            {success.controls}
          </>
        ) : null}
      </div>
      <div
        className={cls("modal-overlay", !success && "hidden")}
        onClick={
          success && success.closeHandler
            ? () => success.closeHandler!()
            : () => null
        }
      ></div>
    </>
  );
};

export default SuccessModal;
