import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import api_client from "../../api/client";

import { tRootState } from "../../store";
import { updateRoles } from "../../store/cacheReducer";

import withAuth from "../../hoc/withAuth/withAuth";

import DashboardLayout from "../../layouts/Dashboard/Dashboard";

import Preloader from "../../components/Preloader/Preloader";

import RoleCard from "../../components/RoleCard/RoleCard";

import { assertNotNull } from "../../utils/func";

const RolesPermission = () => {
  const dispatch = useDispatch();

  const accessToken = useSelector(
    (state: tRootState) => state.auth.accessToken
  );

  assertNotNull(accessToken);

  const roles = useSelector((state: tRootState) => state.cache.roles);

  useEffect(() => {
    api_client({
      url: "/roles",
      method: "GET",
      headers: { Authorization: `Bearer ${accessToken}` },
    })
      .then((res) => {
        dispatch(updateRoles(res.data.data));
      })
      .catch((_err) => {
        // No error handling
      });
  }, [roles?.length, accessToken, dispatch]);

  if (!roles) return <Preloader />;

  return (
    <>
      <DashboardLayout>
        <div className="page-header">
          <div className="page-header__left">
            <h3 className="page-header__heading">Roles and Permissions</h3>
            <ul className="page-header__breadcrumb">
              <li className="page-header__breadcrumb-item">
                <Link to="#">Dashboard</Link>
              </li>
              <li className="page-header__breadcrumb-item">
                <span>Role Management & Permission</span>
              </li>
            </ul>
          </div>
          <div className="page-header__right"></div>
        </div>
        {roles.length ? (
          <div className="roles">
            {roles.map((role) => (
              <Fragment key={role._id}>
                <RoleCard role={role} />
              </Fragment>
            ))}
          </div>
        ) : (
          <div>There are no roles added</div>
        )}
      </DashboardLayout>
    </>
  );
};

export default withAuth(RolesPermission);
